import { useAuth0 } from "@auth0/auth0-react";
import { Button, Collapse, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import { pageTitle, trialDaysCount } from "../settings";
import ContentWithFooter from "./layouts/ContentWithFooter";
import { DollarIcon, SubscriptionCard, SubscriptionTitle } from "./Subscriptions";

const DetailsTrigger = ({ expanded, onClick }: { expanded: boolean; onClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <span className={`cursor-pointer block underline text-sky-800`} onClick={onClick}>
      {expanded ? t("actions.see_less") : t("actions.see_more")}
    </span>
  );
};

const Pricing = () => {
  const { t } = useTranslation();
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const [seeDetails, setSeeDetails] = React.useState(false);

  return (
    <ContentWithFooter>
      <Helmet>
        <title>Pricing - {pageTitle}</title>
      </Helmet>
      <div className="mx-auto text-base">
        <div className="flex flex-col lg:flex-row">
          <SubscriptionCard className="flex flex-grow w-full">
            <div className="flex flex-col h-full w-full">
              <div
                className="flex flex-row items-center text-green-700 text-7xl justify-center"
                style={{ textShadow: "3px 3px 3px #aaa" }}
              >
                <strong>&#9752;</strong>
              </div>
              <SubscriptionTitle title={t("subscriptions.free.title", { dayNo: trialDaysCount })} />
              <div className="text-left md:px-5">
                <div>
                  <strong>{t("subscriptions.free.header")}</strong>
                </div>
                <DetailsTrigger expanded={seeDetails} onClick={() => setSeeDetails(!seeDetails)} />
                <Collapse isOpen={seeDetails}>
                  <Trans i18nKey="subscriptions.free.description">
                    Experience the full power of our home budget application with a 14-day free
                    trial. Enjoy all premium features and tools to help you master your finances,
                    set savings goals, and gain financial clarity. No commitment, no risk — just
                    smart budgeting at your fingertips. Start your free trial today and take the
                    first step towards financial freedom!
                  </Trans>
                </Collapse>
                <div className="pt-12 pb-6 text-center text-3xl">
                  <Trans i18nKey="subscriptions.free.footer">
                    <strong>No credit card required!</strong>
                  </Trans>
                </div>
              </div>

              <div className="mt-auto mx-auto">
                <div className="mt-12">
                  {!isAuthenticated && (
                    <Button
                      rightIcon={<Icon className="ml-2" icon={IconNames.CHART} size={25} />}
                      className="mt-6 !text-xl md:text-xl !px-12 !py-3 md:px-16 md:py-3 rounded shadowtext-lg font-semibold mx-auto hover:opacity-75 flex flex-row items-center"
                      onClick={() => loginWithRedirect()}
                      intent={Intent.SUCCESS}
                    >
                      {t("actions.start_free_trial")}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </SubscriptionCard>
          <SubscriptionCard className="flex flex-grow">
            <div className="flex flex-col h-full">
              <DollarIcon value={1} />
              <SubscriptionTitle title={t("subscriptions.one_month_subscription.title")} />
              <div className="text-left md:px-5">
                <strong>{t("subscriptions.one_month_subscription.header")}</strong>
                <DetailsTrigger expanded={seeDetails} onClick={() => setSeeDetails(!seeDetails)} />
                <Collapse isOpen={seeDetails}>
                  <Trans i18nKey="subscriptions.one_month_subscription.description">
                    <ul className="my-4">
                      <li className="py-2">
                        <strong>Comprehensive Budgeting Tools:</strong> Easily create and manage
                        budgets that suit your lifestyle and goals.
                      </li>
                      <li className="py-2">
                        <strong>Detailed Spending Reports:</strong> Understand your spending habits
                        with in-depth analysis and reports.
                      </li>
                      <li className="py-2">
                        <strong>Savings Goals:</strong> Set and track your savings goals to ensure
                        you're always on the right path.
                      </li>
                      <li className="py-2">
                        <strong>Real-Time Updates:</strong> Keep track of your finances
                      </li>
                      <li className="py-2">
                        <strong>Secure and Private:</strong> Your financial data is protected with
                        the highest security standards.
                      </li>
                      <li className="py-2">
                        <strong>Customer Support:</strong> Enjoy priority customer support to help
                        you with any questions or issues.
                      </li>
                    </ul>
                  </Trans>
                </Collapse>
                <div className="pt-12">
                  <Trans i18nKey="subscriptions.one_month_subscription.footer">
                    Join today and start your journey towards financial freedom. Simplify your
                    budgeting, save more, and achieve your financial goals with our one-month
                    subscription!
                  </Trans>
                </div>
              </div>
              <div className="mt-auto mx-auto">
                <div className="mt-12">
                  {!isAuthenticated && (
                    <Button
                      rightIcon={<Icon className="ml-2" icon={IconNames.CHART} size={25} />}
                      className="mt-6 !text-xl md:text-xl !px-12 !py-3 md:px-16 md:py-3 rounded shadowtext-lg font-semibold mx-auto hover:opacity-75 flex flex-row items-center"
                      onClick={() => loginWithRedirect()}
                      intent={Intent.SUCCESS}
                    >
                      {t("actions.subscribe_monthly")}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </SubscriptionCard>
          <SubscriptionCard className="flex flex-grow">
            <div className="flex flex-col h-full">
              <DollarIcon value={10} />
              <SubscriptionTitle title={t("subscriptions.one_year_subscription.title")} />

              <div className="text-left md:px-5">
                <strong>{t("subscriptions.one_year_subscription.header")}</strong>
                <DetailsTrigger expanded={seeDetails} onClick={() => setSeeDetails(!seeDetails)} />
                <Collapse isOpen={seeDetails}>
                  <Trans i18nKey="subscriptions.one_year_subscription.description">
                    <ul className="my-4">
                      <li className="py-2">
                        <strong>Comprehensive Budgeting Tools:</strong> Easily create and manage
                        budgets that suit your lifestyle and goals.
                      </li>
                      <li className="py-2">
                        <strong>Detailed Spending Reports:</strong> Understand your spending habits
                        with in-depth analysis and reports.
                      </li>
                      <li className="py-2">
                        <strong>Savings Goals:</strong> Set and track your savings goals to ensure
                        you're always on the right path.
                      </li>
                      <li className="py-2">
                        <strong>Real-Time Updates:</strong> Keep track of your finances
                      </li>
                      <li className="py-2">
                        <strong>Secure and Private:</strong> Your financial data is protected with
                        the highest security standards.
                      </li>
                      <li className="py-2">
                        <strong>Customer Support:</strong> Enjoy priority customer support to help
                        you with any questions or issues.
                      </li>
                    </ul>
                  </Trans>
                </Collapse>
                <div className="pt-12">
                  <Trans i18nKey="subscriptions.one_year_subscription.footer">
                    Join today and start your journey towards financial freedom. Simplify your
                    budgeting, save more, and achieve your financial goals with our one-year
                    subscription!
                  </Trans>
                </div>
              </div>
              <div className="mt-auto mx-auto">
                <div className="mt-12">
                  {!isAuthenticated && (
                    <Button
                      rightIcon={<Icon className="ml-2" icon={IconNames.CHART} size={25} />}
                      className="mt-6 !text-xl md:text-xl !px-12 !py-3 md:px-16 md:py-3 rounded shadowtext-lg font-semibold mx-auto hover:opacity-75 flex flex-row items-center"
                      onClick={() => loginWithRedirect()}
                      intent={Intent.SUCCESS}
                    >
                      {t("actions.subscribe_annually")}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </SubscriptionCard>
        </div>
      </div>
    </ContentWithFooter>
  );
};

export default Pricing;
