import { useMutation } from "@apollo/client";
import {
  Button,
  ButtonGroup,
  Classes,
  Dialog,
  DialogBody,
  DialogFooter,
  Icon,
  InputGroup,
  Intent,
  MenuDivider,
  Tooltip,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import React, { MouseEvent, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CREATE_BUDGET_LINK,
  REMOVE_BUDGET_LINK,
  UPDATE_BUDGET,
} from "../../graphql/mutations/budget";
import { UPDATE_USER_SETTINGS } from "../../graphql/mutations/user";
import { USER } from "../../graphql/queries/user";
import { IBudget, IUser } from "../../types/types";
import {
  getUserSettings,
  graphQlError,
  handleError,
  updateUserSettingsObject,
} from "../../utils/utils";
import CustomDialogFooter from "../common/DialogFooter";
import { Notifications } from "../common/notifications";
import { UserContext } from "../WithUserContext";

const Budgets = () => {
  const { t } = useTranslation();
  const userData = useContext<IUser | undefined>(UserContext);
  const settings = getUserSettings(userData?.settings || "{}");
  const [sharingDialogOpen, setSharingDialogOpen] = useState<number | null>(null);
  const [unlinkDialogOpen, setUnlinkDialogOpen] = useState<number | null>(null);
  const [updateUserSettings] = useMutation(UPDATE_USER_SETTINGS, {
    refetchQueries: [{ query: USER, variables: { userId: userData?.user_id } }],
  });
  const [createBudgetLink] = useMutation(CREATE_BUDGET_LINK, {
    refetchQueries: [{ query: USER, variables: { userId: userData?.user_id } }],
  });
  const [removeBudgetLink] = useMutation(REMOVE_BUDGET_LINK, {
    refetchQueries: [{ query: USER, variables: { userId: userData?.user_id } }],
  });

  const [shareBudgetTo, setShareBudgetTo] = useState("");

  const shared: any[] = (settings.shared as any[]) || [];

  const openSharingDialog = useCallback((id: number) => {
    setSharingDialogOpen(id);
  }, []);

  const closeSharingDialog = useCallback(() => {
    setSharingDialogOpen(null);
    setShareBudgetTo("");
  }, []);

  const unlinkBudget = useCallback(() => {
    removeBudgetLink({
      variables: {
        userId: userData?.user_id,
        budgetId: unlinkDialogOpen,
      },
    })
      .then(() => {
        update(
          "shared",
          shared.filter((s) => s.budget_id !== unlinkDialogOpen)
        );
      })
      .catch(handleError)
      .finally(closeUnlinkDialog);
  }, [unlinkDialogOpen]);

  const openUnlinkDialog = useCallback((id: number) => {
    setUnlinkDialogOpen(id);
  }, []);

  const closeUnlinkDialog = useCallback(() => {
    setUnlinkDialogOpen(null);
  }, []);

  const shareBudget = useCallback(() => {
    if (!shared.find((s) => s.user_id === shareBudgetTo) && shareBudgetTo !== userData?.user_id) {
      createBudgetLink({
        variables: {
          object: {
            budget_id: sharingDialogOpen,
            user_id: shareBudgetTo,
          },
        },
      })
        .then(({ data }) => {
          update(
            "shared",
            (shared || []).concat(_.omit(data.insert_budget_users_connector_one, "__typename"))
          );
        })
        .catch(handleError)
        .finally(closeSharingDialog);
    } else {
      closeSharingDialog();
    }
  }, [shareBudgetTo]);

  const update = (key: string, value: string | boolean | number | Record<string, any> | any[]) => {
    updateUserSettings({
      variables: {
        id: userData?.user_id,
        settings: JSON.stringify(updateUserSettingsObject(settings, key, value)),
      },
    })
      .then(() => {
        Notifications &&
          Notifications.show({ message: t("messages.updated") as string, intent: Intent.SUCCESS });
      })
      .catch(graphQlError);
  };

  const setBudgetAsCurrent = useCallback((id: number) => {
    update("current_budget_id", id);
  }, []);

  const { budget_users_connectors } = userData || {};

  return _.isEmpty(budget_users_connectors) ? null : (
    <div>
      <h5 className={`${Classes.HEADING} mt-5`}>
        <span className="inline-block mr-3">{t("labels.budgets")}</span>
        <Tooltip content={t("tooltips.click_on_budget_name_to_make_active")}>
          <Icon icon={IconNames.INFO_SIGN} />
        </Tooltip>
      </h5>
      <ul>
        {budget_users_connectors?.map((budget_users_connector) => {
          const { budget } = budget_users_connector || {};
          return (
            <Budget
              userData={userData as IUser}
              currentBudgetId={settings.current_budget_id as number}
              key={budget.id}
              budget={budget}
              setAsCurrent={setBudgetAsCurrent}
              openSharingDialog={openSharingDialog}
              openUnlinkDialog={openUnlinkDialog}
            />
          );
        })}
      </ul>
      <MenuDivider />
      <Dialog
        isOpen={!!sharingDialogOpen}
        onClose={closeSharingDialog}
        title={t("labels.share_budget")}
        icon={IconNames.FOLLOWER}
      >
        <DialogBody>
          <div className="px-5 mt-4">
            <div dangerouslySetInnerHTML={{ __html: t("messages.share_budget") }} />
            <div className="mt-6">
              <InputGroup
                autoFocus
                value={shareBudgetTo}
                placeholder={t("placeholders.user_id")}
                onChange={(e) => setShareBudgetTo(e.target.value)}
              />
            </div>
          </div>
        </DialogBody>
        <DialogFooter
          actions={
            <ButtonGroup>
              <Button
                intent={Intent.NONE}
                text={t("actions.cancel")}
                onClick={closeSharingDialog}
              />
              <Button
                intent={Intent.PRIMARY}
                text={t("actions.share")}
                onClick={() => {
                  shareBudget();
                }}
              />
            </ButtonGroup>
          }
        />
      </Dialog>
      <Dialog
        isOpen={!!unlinkDialogOpen}
        onClose={closeUnlinkDialog}
        title={t("labels.unlink_budget")}
        icon={IconNames.TRASH}
      >
        <DialogBody>
          <div className="px-5 mt-4">
            <div dangerouslySetInnerHTML={{ __html: t("messages.unlink_budget") }} />
          </div>
        </DialogBody>
        <DialogFooter
          actions={
            <ButtonGroup>
              <Button intent={Intent.NONE} text={t("actions.cancel")} onClick={closeUnlinkDialog} />
              <Button
                intent={Intent.PRIMARY}
                text={t("actions.unlink")}
                onClick={() => {
                  unlinkBudget();
                }}
              />
            </ButtonGroup>
          }
        />
      </Dialog>
    </div>
  );
};

const Budget = ({
  userData,
  currentBudgetId,
  budget,
  setAsCurrent,
  openSharingDialog,
  openUnlinkDialog,
}: {
  userData: IUser;
  currentBudgetId: number;
  budget: IBudget;
  setAsCurrent: (id: number) => void;
  openUnlinkDialog: (id: number) => void;
  openSharingDialog: (id: number) => void;
}) => {
  const { t } = useTranslation();
  const _setAsCurrent = useCallback(() => {
    setAsCurrent(budget.id as number);
  }, []);

  const [updateBudget] = useMutation(UPDATE_BUDGET);

  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(budget.name);

  const openEditDialog = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    setEditing(true);
  }, []);

  const closeEditDialog = useCallback(() => {
    setEditing(false);
  }, []);

  const imOwner = budget.user_id === userData.user_id;

  return (
    <li key={budget.id} className={`p-2 flex items-center gap-2 cursor-pointer hover:bg-gray-600`}>
      {imOwner && (
        <Tooltip targetTagName="div" content={t("tooltips.your_budget")}>
          <Icon className="mr-1" icon={IconNames.CROWN} />
        </Tooltip>
      )}
      {currentBudgetId === budget.id && (
        <Tooltip targetTagName="div" content={t("tooltips.tick_budget")}>
          <Icon className="mr-1" icon={IconNames.TICK} />
        </Tooltip>
      )}
      {/* <DateComponent date={budget.created_at} /> */}
      <Tooltip content={budget.name} targetTagName="div" className="w-full">
        <div
          className="flex-grow mx-2 text-ellipsis text-nowrap w-48 overflow-hidden"
          onClick={_setAsCurrent}
        >
          {budget.name}
        </div>
      </Tooltip>
      <Icon icon={IconNames.EDIT} onClick={openEditDialog} />
      {!imOwner && (
        <Icon
          icon={IconNames.TRASH}
          onClick={() => openUnlinkDialog(budget.id)}
          intent={Intent.DANGER}
        />
      )}
      {imOwner && <Icon icon={IconNames.FOLLOWER} onClick={() => openSharingDialog(budget.id)} />}
      <Dialog
        isOpen={editing}
        onClose={closeEditDialog}
        title={t("labels.edit_budget")}
        icon={IconNames.EDIT}
      >
        <div className="flex flex-col px-5 mt-4">
          <InputGroup
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={t("labels.name")}
          />
          <CustomDialogFooter
            applyEnabled={name !== budget.name}
            onCancel={closeEditDialog}
            onApply={() => {
              updateBudget({ variables: { id: budget.id, changes: { name } } })
                .then(() => {
                  Notifications.show({
                    message: t("messages.saved"),
                    intent: Intent.SUCCESS,
                  });
                })
                .catch(handleError)
                .finally(() => {
                  closeEditDialog();
                });
            }}
          />
        </div>
      </Dialog>
    </li>
  );
};

export default Budgets;
