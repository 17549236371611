import React from "react";
import { IIncome } from "../../types/types";
import FormBuilder from "./FormBuilder";
import IncomeSchema from "./schemas/income";

const IncomeForm = ({
  income,
  onChange,
  onApply,
  onCancel,
}: {
  income: IIncome;
  onApply: (income: IIncome) => Promise<any>;
  onCancel: () => void;
  onChange: (key: string, value: any) => void;
}) => {
  return (
    <div>
      <FormBuilder
        formSchema={IncomeSchema}
        entity={income}
        onChange={onChange}
        onApply={onApply}
        onCancel={onCancel}
        additionalOptions={{}}
      />
    </div>
  );
};

IncomeForm.propTypes = {};

export default IncomeForm;
