import { useAuth0 } from "@auth0/auth0-react";
import {
  Alignment,
  Button,
  Classes,
  Divider,
  Icon,
  Menu,
  MenuItem,
  Navbar,
  Popover,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, Route, Routes } from "react-router-dom";
import data from "../../package.json";
import { isMobile, isWebViewFromAndroid } from "../utils/utils";
import Changelog from "./Changelog";
import CookieNotice from "./common/CookieNotice";
import Contact from "./Contact";
import Cookies from "./Cookies";
import Help from "./Help";
import Home from "./Home";
import PageContent from "./layouts/PageContent";
import Pricing from "./Pricing";
import PrivacyPolicy from "./PrivacyPolicy";
import Roadmap from "./Roadmap";
import MobileLoginBtn from "./user/MobileLoginBtn";

export const PublicNavigation = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const { t, i18n } = useTranslation();

  return (
    <Navbar className={`${Classes.DARK}`}>
      <Navbar.Group align={Alignment.LEFT}>
        {isAuthenticated ? (
          <Link to="/dashboard">{t("labels.budget")}</Link>
        ) : (
          <React.Fragment>
            <Link to="/" className="mr-3">
              <Icon icon={IconNames.HOME} className="mr-1" size={isMobile() ? 22 : 18} />
              <span className="hidden md:inline-block">{t("labels.home")}</span>
            </Link>
            <Link to="/contact" className="mr-3">
              <Icon icon={IconNames.ENVELOPE} className="mr-1" size={isMobile() ? 22 : 18} />
              <span className="hidden md:inline-block">{t("labels.contact_us")}</span>
            </Link>
            <Link to="/pricing" className="mr-3">
              <Icon icon={IconNames.DOLLAR} className="mr-1" size={isMobile() ? 22 : 18} />
              <span className="hidden md:inline-block">{t("labels.pricing")}</span>
            </Link>
          </React.Fragment>
        )}
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        {isWebViewFromAndroid() ? (
          <MobileLoginBtn />
        ) : (
          <Button
            minimal
            className="text-2xl md:text-base"
            onClick={() => loginWithRedirect()}
            text={t("labels.log_in")}
            icon={IconNames.LOG_IN}
          />
        )}
        <Divider />
        <Popover
          content={
            <Menu>
              <MenuItem
                className="text-2xl md:text-base"
                onClick={() => i18n.changeLanguage("pl")}
                text={"PL"}
              />
              <MenuItem
                className="text-2xl md:text-base"
                onClick={() => i18n.changeLanguage("en")}
                text={"EN"}
              />
            </Menu>
          }
        >
          <div className="text-xl md:text-base">{i18n.language.toUpperCase()}</div>
        </Popover>
      </Navbar.Group>
    </Navbar>
  );
};

const PublicRoutes = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="fixed w-full bg-black opacity-100 h-12">
        <PublicNavigation />
      </div>

      <PageContent>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<div>Sth off</div>} />
          <Route path="/help" element={<Help />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/roadmap" element={<Roadmap />} />
          <Route path="/changelog" element={<Changelog />} />
        </Routes>
        <div className="z-50 bg-gray-700 text-white p-2 fixed mr-3 bottom-0 right-0">
          {t("labels.version", { version: data.version })}
        </div>
        <CookieNotice />
      </PageContent>
    </div>
  );
};

export default PublicRoutes;
