import { HTMLTable } from "@blueprintjs/core";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { FinalEntryToInsert } from "../../utils/importUtils";
import { PREVIEW_HEADERS } from "./PreviewTable";
import { parseDate } from "../../utils/utils";
import { dateFormatWithTime } from "../../settings";

type Props = {
  finalDataToImport?: FinalEntryToInsert[];
  setFinalDataToImport: (data: FinalEntryToInsert[]) => void;
};

const FinalImportTable = ({ finalDataToImport, setFinalDataToImport }: Props) => {
  const { t } = useTranslation();
  return (
    <HTMLTable bordered striped style={{ width: "100%", overflowX: "auto" }}>
      <thead>
        <tr>
          {_.map([...PREVIEW_HEADERS, "import"], (key) => (
            <th key={key}>{t(`import.${key}`)}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {_.map(finalDataToImport, (row, idx) => {
          return (
            <tr key={idx}>
              {_.map(PREVIEW_HEADERS, (key) => {
                return key === "date" ? (
                  <td className="break-words" key={key}>
                    {parseDate(row[key] as string).toFormat(dateFormatWithTime)}
                  </td>
                ) : (
                  <td className="break-words" key={key}>
                    {_.has(row[key], "name") ? (row[key].name as string) : (row[key] as string)}
                  </td>
                );
              })}
              <td>
                <input
                  type="checkbox"
                  checked={row.checked}
                  onChange={() => {
                    if (finalDataToImport) {
                      const newData = [...finalDataToImport];
                      newData[idx].checked = !newData[idx].checked;
                      setFinalDataToImport(newData);
                    }
                  }}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </HTMLTable>
  );
};

export default FinalImportTable;
