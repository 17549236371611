import { DateTime } from "luxon";
import React, { ReactElement } from "react";
import { parseDate } from "../../utils/utils";
import { dateFormat } from "../../settings";

interface IDateProps {
  date: string | DateTime;
  className?: string;
}

export default function Date({ date, className }: IDateProps): ReactElement {
  return <span className={className}>{date && parseDate(date).toFormat(dateFormat)}</span>;
}
