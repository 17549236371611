import { useQuery } from "@apollo/client";
import { HTMLTable, Spinner } from "@blueprintjs/core";
import _ from "lodash";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CATEGORIES_LIST } from "../../graphql/queries/categories";
import { PAYEE_LIST } from "../../graphql/queries/payees";
import { PreparedEntry } from "../../utils/importUtils";
import CategoriesSelect from "../common/CategoriesSelect";
import PayeeSelect from "../common/PayeeSelect";
import { BudgetContext } from "../WithBudgetContext";
import { ICategory, IPayee } from "../../types/types";
import { parseDate } from "../../utils/utils";
import { dateFormatWithTime } from "../../settings";

type Props = {
  preparedData?: PreparedEntry[];
  setPreparedData: React.Dispatch<React.SetStateAction<PreparedEntry[] | undefined>>;
};

export const PREVIEW_HEADERS = ["date", "amount", "category", "payee", "description"];

const PreviewTable = ({ preparedData, setPreparedData }: Props) => {
  const { t } = useTranslation();

  const currentBudget = useContext(BudgetContext);
  const { data: categories, loading: loadingCategories } = useQuery(CATEGORIES_LIST, {
    variables: { budgetId: currentBudget?.id },
  });

  const { loading: loadingPayees, data: payees } = useQuery(PAYEE_LIST);

  return (
    <HTMLTable bordered striped style={{ width: "100%", overflowX: "auto" }}>
      <thead>
        <tr>
          {_.map(PREVIEW_HEADERS, (key) => (
            <th key={key}>{t(`import.${key}`)}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {_.map(preparedData, (row, idx) => {
          return (
            <tr key={idx}>
              {_.map(PREVIEW_HEADERS, (key) => {
                return (
                  <PreviewTableCell
                    key={key}
                    cellKey={key}
                    row={row}
                    idx={idx}
                    onChange={setPreparedData}
                    categories={categories?.categories}
                    payees={payees?.payees}
                    loadingCategories={loadingCategories}
                    loadingPayees={loadingPayees}
                  />
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </HTMLTable>
  );
};

type PreviewTableCellProps = {
  row: PreparedEntry;
  cellKey: string;
  onChange: React.Dispatch<React.SetStateAction<PreparedEntry[] | undefined>>;
  idx: number;
  loadingCategories: boolean;
  loadingPayees: boolean;
  categories?: ICategory[];
  payees?: IPayee[];
};

const PreviewTableCell = ({
  row,
  cellKey,
  onChange,
  idx,
  loadingCategories,
  loadingPayees,
  payees,
  categories,
}: PreviewTableCellProps) => {
  switch (cellKey) {
    case "payee":
      return (
        <td>
          {loadingPayees ? (
            <Spinner />
          ) : (
            <div>
              {<div className="mb-2">{row["_orginalPayee"]}</div>}
              <PayeeSelect
                disabled={false}
                initialPayee={payees ? payees[0] : undefined}
                onChange={(payee) =>
                  onChange((current) => {
                    if (current) {
                      current[idx]["payee"] = payee || "";
                      return [...current];
                    }
                    return current;
                  })
                }
                payeesList={payees}
              />
            </div>
          )}
        </td>
      );
    case "category":
      return (
        <td>
          {loadingCategories ? (
            <Spinner />
          ) : (
            <CategoriesSelect
              includeTransfers={false}
              isTransfer={false}
              filterOutCategories={[]}
              filterOutNestedCategories={false}
              initialCategory={categories ? categories[0] : undefined}
              categoryList={categories}
              onChange={(category) =>
                onChange((current) => {
                  if (current) {
                    current[idx]["category"] = category || "";
                    return [...current];
                  }
                  return current;
                })
              }
            />
          )}
        </td>
      );
    case "date":
      return <td>{parseDate(row[cellKey]).toFormat(dateFormatWithTime)}</td>;
    default:
      return <td className="break-words">{(row[cellKey] as string) || ""}</td>;
  }
};

export default PreviewTable;
