import React from "react";
import { Trans } from "react-i18next";

const quotes = [
  {
    author: "George R.R. Martin",
    quote:
      "A reader lives a thousand lives before he dies . . . The man who never reads lives only one.",
  },
  {
    author: "Harper Lee",
    quote: "Until I feared I would lose it, I never loved to read. One does not love breathing.",
  },
  {
    author: "C.S. Lewis",
    quote: "You can never get a cup of tea large enough or a book long enough to suit me.",
  },
  {
    author: "Groucho Marx",
    quote:
      "I find television very educating. Every time somebody turns on the set, I go into the other room and read a book.",
  },
  {
    author: "Malcolm X",
    quote:
      "My alma mater was books, a good library…. I could spend the rest of my life reading, just satisfying my curiosity.",
  },
  {
    author: "Roald Dahl",
    quote:
      "I have a passion for teaching kids to become readers, to become comfortable with a book, not daunted. Books shouldn't be daunting, they should be funny, exciting and wonderful; and learning to be a reader gives a terrific advantage.",
  },
  {
    author: "George Bernard Shaw",
    quote: "Make it a rule never to give a child a book you would not read yourself.",
  },
  {
    author: "Abraham Lincoln",
    quote:
      "Books serve to show a man that those original thoughts of his aren't very new after all.",
  },
  {
    author: "Mark Twain",
    quote: "The man who does not read good books is no better than the man who can't.",
  },
  {
    author: "Napoléon Bonaparte",
    quote: "Show me a family of readers, and I will show you the people who move the world.",
  },
  { author: "John Steinbeck", quote: "I guess there are never enough books." },
  {
    author: "Voltaire",
    quote:
      "Despite the enormous quantity of books, how few people read! And if one reads profitably, one would realize how much stupid stuff the vulgar herd is content to swallow every day.",
  },
  { author: "J.K. Rowling", quote: "If you don't like to read, you haven't found the right book." },
  {
    author: "Jim Rohn",
    quote: "Reading is essential for those who seek to rise above the ordinary.",
  },
  { author: "Epictetus", quote: "Books are the training weights of the mind." },
  {
    author: "John Maxwell",
    quote: "Your mind will give back to you exactly what you put into it.",
  },
  {
    author: "David Perell",
    quote:
      "The paradox of reading: The books you read will profoundly change you even though you'll forget the vast majority of what you read.",
  },
  {
    author: "E.B. White",
    quote:
      "Books hold most of the secrets of the world, most of the thoughts that men and women have had.",
  },
  {
    author: "Greg McKeown",
    quote: "Reading a book is among the most high-leverage activities on earth.",
  },
  {
    author: "Ryan Holiday",
    quote:
      "The purpose of reading is not just raw knowledge. It's that it is part of the human experience. It helps you find meaning, understand yourself, and make your life better.",
  },
  {
    author: "James Clear",
    quote:
      "Read books that are relevant to what you want to achieve and reading will never seem boring.",
  },
  {
    author: "Dr. Seuss",
    quote:
      "The more that you read, the more things you will know. The more that you learn, the more places you'll go.",
  },
  { author: "Honoré de Balzac", quote: "Reading brings us unknown friends." },
  {
    author: "Walt Disney",
    quote: "There is more treasure in books than in all the pirate's loot on Treasure Island.",
  },
  { author: "Garrison Keillor", quote: "A book is a gift you can open again and again." },
  { author: "Fran Lebowitz", quote: "Think before you speak. Read before you think." },
  { author: "Mary Schmich", quote: "Reading is a discount ticket to everywhere." },
  { author: "Holbrook Jackson", quote: "Never put off till tomorrow the book you can read today." },
  { author: "Lisa See", quote: "Read a thousand books, and your words will flow like a river." },
  { author: "Walter Moers", quote: "Reading is an intelligent way of not having to think." },
  { author: "Stendhal", quote: "A good book is an event in my life." },
  {
    author: "Harold Bloom",
    quote: "Reading well is one of the great pleasures that solitude can afford you.",
  },
  {
    author: "Rene Descartes",
    quote:
      "The reading of all good books is like a conversation with the finest minds of past centuries.",
  },
  { author: "Edmund Wilson", quote: "No two persons ever read the same book." },
  {
    author: "Nathan W. Morris",
    quote: "Every time you borrow money, you're robbing your future self.",
  },
  {
    author: "Benjamin Franklin",
    quote: "Beware of little expenses. A small leak will sink a great ship.",
  },
  {
    author: "Warren Buffett",
    quote: "Do not save what is left after spending, but spend what is left after saving.",
  },
  {
    author: "Philip Fisher",
    quote:
      "The stock market is filled with individuals who know the price of everything, but the value of nothing.",
  },
  {
    author: "Dave Ramsey",
    quote: "You must gain control over your money, or the lack of it will forever control you.",
  },
  { author: "P. T. Barnum", quote: "Money is a terrible master but an excellent servant." },
  {
    author: "James W. Frick",
    quote:
      "Don't tell me where your priorities are. Show me where you spend your money, and I'll tell you what they are.",
  },
  {
    author: "Suze Orman",
    quote:
      "Look everywhere you can to cut a little bit from your expenses. It will all add up to a meaningful sum.",
  },
  { author: "Thomas Jefferson", quote: "Never spend your money before you have it." },
  {
    author: "John C. Maxwell",
    quote: "A budget is telling your money where to go instead of wondering where it went.",
  },
  {
    author: "Frank A. Clark",
    quote:
      "Many folks think they aren't good at earning money, when what they don't know is how to use it.",
  },
  {
    author: "Woodrow Wilson",
    quote: "The way to stop financial joyriding is to arrest the chauffeur, not the automobile.",
  },
  {
    author: "Ayn Rand",
    quote:
      "Money is only a tool. It will take you wherever you wish, but it will not replace you as the driver.",
  },
  {
    author: "Epictetus",
    quote: "Wealth consists not in having great possessions, but in having few wants.",
  },
  {
    author: "Edmund Burke",
    quote:
      "If we command our wealth, we shall be rich and free. If our wealth commands us, we are poor indeed.",
  },
  {
    author: "Henry David Thoreau",
    quote:
      "The cost of a thing is the amount of what I call life which is required to be exchanged for it, immediately or in the long run.",
  },
  {
    author: "Brigham Young",
    quote:
      "If you wish to get rich, save what you get. A fool can earn money; but it takes a wise man to save and dispose of it to his own advantage.",
  },
  {
    author: "P.T. Barnum",
    quote:
      "A penny here and a dollar there, placed at interest, goes on accumulating, and in this way, the desired result is attained.",
  },
  {
    author: "Zig Ziglar",
    quote:
      "Time well-spent results in more money to spend, more money to save, and more time to vacation.",
  },
  {
    author: "Steve Jobs",
    quote:
      "My favorite things in life don't cost any money. It's really clear that the most precious resource we all have is time.",
  },
  {
    author: "Christopher Rice",
    quote:
      "Everyday is a bank account, and time is our currency. No one is rich, no one is poor, we've got 24 hours each.",
  },
  {
    author: "Shirley Temple",
    quote: "Time is money. Wasted time means wasted money means trouble.",
  },
  { author: "John L Beckley", quote: "Most people don't plan to fail they fail to plan." },
  { author: "Napoleon Hills", quote: "Both poverty and riches are the offspring of thought." },
  {
    author: "Sinclair Lewis",
    quote: "It isn't what you earn but how you spend it that fixes your class.",
  },
  {
    author: "Donald Rumsfeld",
    quote: "Think ahead, don't let day to day operations drive out planning.",
  },
  {
    author: "Jim Rohn",
    quote: "If your outgo exceeds your income, your upkeep becomes your downfall.",
  },
  {
    author: "Jonathan Swift",
    quote: "A wise person should have money in their head, but not in their heart.",
  },
  {
    author: "Kishorkumar Bapalli",
    quote: "Financial planning and discipline is key to one's financial freedom.",
  },
  {
    author: "Alan Lakein",
    quote:
      "Planning is bringing the future into the present so that you can do something about it.",
  },
  {
    author: "Peter F. Drucker",
    quote: "Unless commitment is made, there are only promises and hopes but no plans.",
  },
  {
    author: "Will Robinson",
    quote:
      "Financial fitness is not a pipe dream or a state of mind it's a reality if you are willing to pursue it and embrace it.",
  },
  {
    author: "Pablo Picasso",
    quote:
      "Our goals can only be reached through a vehicle of a plan in which we must fervently believe, and upon which we must vigorously act.",
  },
  {
    author: "",
    quote:
      "It's not whether you're right or wrong that's important, but how much money you make when you're right and how much you lose when you're wrong.",
  },
  {
    author: "Thomas J. Stanley",
    quote:
      "If your goal is to become financially secure, you'll likely attain it, but if your motive is to make money to spend money on a good life you're never gonna make it.",
  },
  { author: "Mike Figgis", quote: "They call it a budget so you don't budge from it." },
  { author: "Leslie Tayne", quote: "Budgeting only has one rule, do not go over the budget." },
  { author: "Elizabeth Warren", quote: "Budgeting your money is the key to having enough." },
  { author: "George Washington", quote: "We must consult our means rather than our wishes." },
  {
    author: "Robert Kiyosaki",
    quote: "Financial freedom is available to those who learn about it and work for it.",
  },
  {
    author: "Idowu koyenikan",
    quote: "When money realises its in good hands it wants to stay and multiply in those hands.",
  },
  {
    author: "Barack Obama",
    quote:
      "A budget is more than just a series of numbers on a page, it is an embodiment of our values.",
  },
  {
    author: "Money Tree Man",
    quote:
      "A personal budget is a manifestation of your decision to grab your finances by the balls.",
  },
  {
    author: "Jaime Lerner",
    quote:
      "If you want creativity, take a zero off your budget. If you want sustainability, take off two zeros.",
  },
  {
    author: "Rosette Wamambe",
    quote:
      "Budgeting is not for those without money but for everyone who wants to ensure that their money is enough.",
  },
  {
    author: "Phil Gramm",
    quote:
      "Balancing the budget is like going to heaven. Everybody wants to do it, but nobody wants to do what you have to do to get there.",
  },
  { author: "Henry Ford", quote: "Whether you think you can or think you can't, you're right." },
  {
    author: "Larry Dyson",
    quote:
      "With an understanding of your budget and your true needs, you may start putting away more money than you ever expected.",
  },
  {
    author: "Tere Stouffer",
    quote: "Used correctly, a budget doesn't restrict you; it empowers you.",
  },
  {
    author: "Joe Biden",
    quote: "Don't tell me what you value, show me your budget, and I'll tell you what you value.",
  },
  { author: "Kevin McCarthy", quote: "Budgets are blueprints and priorities." },
  {
    author: "Lisa Conway-Hughes",
    quote:
      "The secret to budgeting is that it needs to be honest. Not what you think it should be or wish it could be, but what it really is.",
  },
  {
    author: "Jae K. Shim",
    quote: "A budget is a financial plan to control future operations and results.",
  },
  { author: "Suze Orman", quote: "Just because you can afford it doesn't mean you should buy it." },
  {
    author: "Dave Ramsey",
    quote: "A budget is telling your money where to go instead of wondering where it went.",
  },
  {
    author: "Warren Buffett",
    quote: "Someone's sitting in the shade today because someone planted a tree a long time ago.",
  },
  {
    author: "Benjamin Franklin",
    quote: "Beware of little expenses; a little leak will sink a great ship.",
  },
  { author: "Unknown", quote: "Reading is to the mind what exercise is to the body." },
  { author: "Unknown", quote: "A budget is a plan for every dollar you have." },
  { author: "Unknown", quote: "Books are a uniquely portable magic." },
  {
    author: "Unknown",
    quote:
      "The best investment you can make is in yourself. The more you learn, the more you earn.",
  },
];

const Quotes = () => {
  const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];

  return (
    <div className="md:flex flex-row items-center mt-24 mb-24">
      <div className="flex-1 text-xl">
        <Trans i18nKey="main_page.books.text">
          <div className="text-center">
            <strong className="text-2xl mb-4 block">{randomQuote.author}:</strong>
            <i>{randomQuote.quote}</i>
          </div>
        </Trans>
      </div>
    </div>
  );
};

export default Quotes;
