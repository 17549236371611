import React from "react";
import { Link, LinkProps } from "react-router-dom";
import useSelectedDate from "../../hooks/useSelectedDate";
import { dateFormat } from "../../settings";

interface Props extends LinkProps {
  children: React.ReactNode;
}

const InternalLink = ({ className, to, children }: Props) => {
  const { selectedDate } = useSelectedDate();
  const dateParam = selectedDate.toFormat(dateFormat);

  return (
    <Link className={className} to={`${to}?date=${dateParam}`}>
      {children}
    </Link>
  );
};

export default InternalLink;
