import { ApolloQueryResult, useMutation, useQuery } from "@apollo/client";
import { Icon, Intent, Spinner } from "@blueprintjs/core";
import React, { useContext } from "react";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import { DateTime } from "luxon";
import { KeyboardEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UPDATE_SPLITTED_ENTRY } from "../../graphql/mutations/splitted_entries";
import { ICategory, ICurrency, IEntry, ISingleEntry } from "../../types/types";
import { graphQlError } from "../../utils/utils";
import CategoriesSelect from "../common/CategoriesSelect";
import InternalLink from "../common/InternalLink";
import MoneyValue from "../common/MoneyValue";
import { Notifications } from "../common/notifications";
import { ValueInput } from "../forms/FormBuilder";
import EntityIcon from "../common/EntityIcon";
import { CATEGORIES_LIST } from "../../graphql/queries/categories";
import { BudgetContext } from "../WithBudgetContext";

type Props = {
  entry: IEntry;
  refetch:
    | ((
        variables?:
          | Partial<{
              periodStart: DateTime;
              periodEnd: DateTime;
            }>
          | undefined
      ) => Promise<ApolloQueryResult<any>>)
    | (() => Promise<[ApolloQueryResult<any>, ApolloQueryResult<any>]>);
};

const InlineCategoryEdit = ({ entry, refetch }: Props) => {
  const { t } = useTranslation();

  const currentBudget = useContext(BudgetContext);
  const { data, loading: loadingCategories } = useQuery(CATEGORIES_LIST, {
    variables: { budgetId: currentBudget?.id },
  });

  const currency = useMemo(
    () => JSON.parse((entry.account?.currency as ICurrency).toString() || "{}"),
    [entry]
  );
  const [updateSplittedEntry] = useMutation(UPDATE_SPLITTED_ENTRY);
  const [editingSplittedEntry, setEditingSplittedEntry] = useState<{
    key: string;
    value: Partial<ISingleEntry>;
  } | null>(null);

  const onUpdateCategory = (category: ICategory) => {
    updateSplittedEntry({
      variables: {
        id: editingSplittedEntry?.value.id,
        changes: {
          category_id: category.id,
        },
      },
    })
      .then((res) => {
        res &&
          Notifications &&
          Notifications.show({ message: t("messages.updated"), intent: Intent.SUCCESS });
      })
      //@ts-ignore
      .then(() => refetch())
      .catch(graphQlError)
      .finally(closeEditing);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") onUpdateSplittedEntry();
    if (e.key === "Escape") {
      closeEditing();
    }
  };

  const closeEditing = useCallback(() => {
    setEditingSplittedEntry(null);
  }, []);

  const onUpdateSplittedEntry = () => {
    const value =
      editingSplittedEntry?.key === "value"
        ? editingSplittedEntry?.value.value
        : editingSplittedEntry?.value.category?.id;

    updateSplittedEntry({
      variables: {
        id: editingSplittedEntry?.value.id,
        changes: {
          [editingSplittedEntry?.key as string]: value,
        },
      },
    })
      .then((res) => {
        res &&
          Notifications &&
          Notifications.show({ message: t("messages.updated"), intent: Intent.SUCCESS });
      })
      .catch(graphQlError)
      .finally(closeEditing);
  };

  return _.isEmpty(entry.splitted_entries) ? (
    <InternalLink to="/categories/">
      <EntityIcon icon={entry.category?.icon} type="category" />
      <span className="inline-block">{entry.category?.name}</span>
    </InternalLink>
  ) : (
    <div>
      {_.map(entry.splitted_entries, (se) => (
        <div key={se.id} className="flex flex-col xl:flex-row xl:items-center w-full">
          {se.id === editingSplittedEntry?.value.id && editingSplittedEntry?.key === "category" ? (
            <div className="with-inline-edit-icon flex flex-row flex-grow xl:w-full mb-2 xl:mb-0">
              {loadingCategories ? (
                <Spinner />
              ) : (
                <React.Fragment>
                  <CategoriesSelect
                    includeTransfers={false}
                    isTransfer={false}
                    filterOutCategories={[]}
                    filterOutNestedCategories={false}
                    initialCategory={se.category}
                    onChange={onUpdateCategory}
                    categoryList={data?.categories}
                  />
                  <Icon
                    className="inline-edit-icon mx-2"
                    icon={IconNames.CROSS}
                    onClick={closeEditing}
                  />
                </React.Fragment>
              )}
            </div>
          ) : (
            <div className="with-inline-edit-icon flex flex-row flex-grow">
              <InternalLink className="flex-grow mr-2" to={`/categories/${se.category?.id}`}>
                <EntityIcon icon={se.category?.icon} type="category" />
                <span className="inline-block">{se.category?.name}</span>
              </InternalLink>
              <Icon
                className="inline-edit-icon mx-2"
                icon={IconNames.EDIT}
                onClick={() => setEditingSplittedEntry({ key: "category", value: se })}
              />
            </div>
          )}
          {se.id === editingSplittedEntry?.value.id && editingSplittedEntry?.key === "value" ? (
            <div className="flex flex-row relative w-full xl:w-auto text-right">
              <ValueInput
                entityKey="value"
                onChange={(_, value) =>
                  setEditingSplittedEntry({
                    key: "value",
                    value: { ...se, value: value },
                  })
                }
                onKeyDown={onKeyDown}
                value={editingSplittedEntry.value.value as number}
              />
              <div className="flex flex-row absolute right-0 top-2">
                <Icon icon={IconNames.TICK} onClick={onUpdateSplittedEntry} />
                <Icon icon={IconNames.CROSS} onClick={closeEditing} />
              </div>
            </div>
          ) : (
            <div
              className="relative w-full xl:w-auto text-right"
              onClick={() => setEditingSplittedEntry({ key: "value", value: se })}
            >
              <MoneyValue
                value={_.isString(se.value) ? parseFloat(se.value) : se.value ?? 0}
                currency={currency}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default InlineCategoryEdit;
