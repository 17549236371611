import { Trans, useTranslation } from "react-i18next";
import ContentWithFooter from "./layouts/ContentWithFooter";
import { ContactForm } from "./common/ContactForm";
import { pageTitle } from "../settings";
import { Helmet } from "react-helmet";
import React from "react";
import SocialMediaLinks from "./common/SocialMediaLinks";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <ContentWithFooter>
      <Helmet>
        <title>Contact - {pageTitle}</title>
      </Helmet>
      <h2 className="bp5-heading !py-12 pb-6 text-center">{t("main_page.contact_form.title")}</h2>
      <div className="w-full max-w-md mx-auto text-xl">
        <Trans i18nKey="main_page.contact_form.text">
          Whether you're curious about our features, need assistance with your account, or just want
          to share your thoughts, we'd love to hear from you. Your feedback helps us improve Budget
          4 You and provide the best possible experience for our users.
        </Trans>
      </div>
      <div className="mx-auto w-full max-w-md my-12 text-left">
        <ContactForm />
      </div>
      <div className="w-full max-w-md mx-auto my-12 text-xl">
        <SocialMediaLinks />
      </div>
    </ContentWithFooter>
  );
};

export default Contact;
