import React from "react";
import _ from "lodash";
import Carousel from "./Carousel";
import { useTranslation } from "react-i18next";

const AFFILIATIONS = {
  en: [
    {
      link: "https://a.co/d/02wULiVN",
      img: "https://m.media-amazon.com/images/I/71ccvy2+MIL._SL1360_.jpg",
      description:
        "It is waking up in the morning and pinching yourself black-and-blue that OMG, this is my life, and it is freaking awesome. You live in your dream house, but there's no mortgage. No alarm clock, no boss, no bills. No claims on the day's time other than what you choose. It is making more money before breakfast than you made for an entire week at your last job. It is a crazy expensive car parked in your garage, a victorious symbol that your dreams no longer sleep in fantasies, but are awake with reality.",
      title: "UNSCRIPTED: Life, Liberty, and the Pursuit of Entrepreneurship",
    },
    {
      link: "https://a.co/d/0hWnJST0",
      img: "https://m.media-amazon.com/images/I/616BYPbOCyL._SL1500_.jpg",
      title: "The Millionaire Fastlane: Crack the Code to Wealth and Live Rich for a Lifetime",
      description:
        "<div><strong>Has the 'settle-for-less' financial plan become your plan for wealth? That sounds something like this:</strong><br/>Graduate from college, get a good job, save 10% of your paycheck, buy a used car, cancel the movie channels, quit drinking expensive Starbucks mocha lattes, save and penny-pinch your life away, trust your life-savings to Wall Street, and one day, when you are oh, say, 65 years old, you can retire rich.</div>",
    },
  ],
  pl: [
    {
      link: "https://a.co/d/00uxAapJ",
      img: "https://m.media-amazon.com/images/I/71CGtjx7hfL._SL1500_.jpg",
      title: "Jak zadbać o własne finanse?",
      description: "Książka o finansach osobistych",
    },
    {
      link: "https://a.co/d/02skJhrD",
      img: "https://m.media-amazon.com/images/I/81Bl3Iubc0L._SL1500_.jpg",
      title: "Jak inwestować i pomnażać oszczędności",
      description: "Książka o finansach osobistych",
    },
  ],
};

function Affiliations() {
  const { i18n } = useTranslation();

  return (
    <div>
      <Carousel>
        {_.map(AFFILIATIONS[i18n.language as "en" | "pl"], (affiliation) => {
          return (
            <div className="h-full" key={affiliation.link}>
              <h1 className="mb-12 text-center font-semibold text-2xl md:text-4xl">
                <a
                  href={affiliation.link}
                  className="block mx-auto"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {affiliation.title}
                </a>
              </h1>
              <div className="w-full h-full flex">
                <a href={affiliation.link} target="_blank" rel="noopener noreferrer">
                  <div className="image flex-1 max-w-sm">
                    <img src={affiliation.img} alt={affiliation.title} />
                  </div>
                  <div className="flex-1 hidden md:flex">
                    <div
                      className="description text-xl"
                      dangerouslySetInnerHTML={{ __html: affiliation.description }}
                    />
                  </div>
                </a>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}

export default Affiliations;
