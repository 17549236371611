import React from "react";
import { IBudgetEntry } from "../../types/types";
import FormBuilder from "./FormBuilder";
import BudgetEntrySchema from "./schemas/budgetEntry";

const BudgetEntryForm = ({
  budgetEntry,
  onChange,
  onApply,
  onCancel,
  additionalOptions,
}: {
  budgetEntry: IBudgetEntry;
  onApply: (budgetEntry: IBudgetEntry) => Promise<any>;
  onCancel: () => void;
  onChange: (key: string, value: any) => void;
  additionalOptions?: any;
}) => {
  return (
    <div>
      <FormBuilder
        formSchema={BudgetEntrySchema}
        entity={budgetEntry}
        onCancel={onCancel}
        onApply={onApply}
        onChange={onChange}
        additionalOptions={additionalOptions}
      />
    </div>
  );
};

BudgetEntryForm.propTypes = {};

export default BudgetEntryForm;
