import { useQuery } from "@apollo/client";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogBody,
  DialogFooter,
  Intent,
  MenuItem,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemModifiers, Select } from "@blueprintjs/select";
import _ from "lodash";
import {
  default as React,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useCreateCategory } from "../../graphql/hooks/category";
import { CATEGORIES_LIST } from "../../graphql/queries/categories";
import { ICategory } from "../../types/types";
import { getCategoryLabel, graphQlError } from "../../utils/utils";
import { UserContext } from "../WithUserContext";
import EntityIcon from "./EntityIcon";
import WithLoadingSpinner from "./WithLoadingSpinner";
import { BudgetContext } from "../WithBudgetContext";

const createItem = (name: string) => {
  return {
    name,
  };
};

const renderCreateItemOption = (
  query: string,
  active: boolean,
  handleClick: React.MouseEventHandler<HTMLElement>
) => {
  return (
    <MenuItem
      icon={IconNames.ADD}
      text={`Create "${query}"`}
      active={active}
      onClick={handleClick}
      shouldDismissPopover={false}
    />
  );
};
const renderItem = (
  item: Partial<ICategory>,
  {
    handleClick,
    modifiers,
  }: {
    handleClick: (event: React.MouseEvent) => void;
    modifiers: ItemModifiers;
  }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return <CategorySelectItem handleClick={handleClick} active={modifiers.active} category={item} />;
};

const CategorySelectItem = ({
  category,
  handleClick,
  active,
}: {
  category: Partial<ICategory>;
  active: boolean;
  handleClick: (event: React.MouseEvent) => void;
}) => {
  return (
    <MenuItem
      active={active}
      onClick={handleClick}
      key={category.id}
      text={
        <div className="flex flex-row items-center">
          <EntityIcon icon={category.icon} type="category" />
          <span className="inline-block ml-2">{category.name}</span>
        </div>
      }
    />
  );
};

export interface ICategorySelect {
  disabled?: boolean;
  fill?: boolean;
  filterOutCategories: string[];
  filterOutNestedCategories: boolean;
  includeTransfers: boolean;
  initialCategory?: ICategory;
  isTransfer: boolean;
  onChange: (category: ICategory) => void;
  categoryList?: ICategory[];
}

const CategorySelect = ({
  categoryList,
  disabled = false,
  fill = false,
  filterOutCategories,
  filterOutNestedCategories,
  includeTransfers,
  initialCategory,
  isTransfer,
  onChange,
}: ICategorySelect) => {
  const userData = useContext(UserContext);
  const selectRef = useRef(null);
  const { t } = useTranslation();
  const currentBudget = useContext(BudgetContext);
  const [createCategory] = useCreateCategory();

  const [query, setQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);

  useEffect(() => {
    setSelectedCategory(initialCategory);
  }, [initialCategory]);

  const filterCategories = (query: string, category: ICategory) => {
    return getCategoryLabel(category).toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  const [confirmDialogOptions, setConfirmDialogOptions] = useState<{
    open: boolean;
    category: ICategory | null;
  }>({ open: false, category: null });

  const onSelectCategory = (category: ICategory) => {
    if (category.id) {
      setSelectedCategory(category);
      onChange(category);
    } else {
      setConfirmDialogOptions({ open: true, category });
    }
  };

  const createNewCategory = () => {
    createCategory({
      variables: {
        object: {
          name: confirmDialogOptions.category?.name,
          budget_id: currentBudget?.id,
          //@ts-ignore
          user_id: userData?.user_id,
        },
      },
    })
      .then((res) => {
        closeDialog();
        setSelectedCategory(res.data.insert_categories_one);
        return onChange(res.data.insert_categories_one);
      })
      .catch(graphQlError)
      .finally(() => {
        setQuery("");
        closeDialog();
        // selectRef.current?.close();
      });
  };

  const closeDialog = useCallback(() => {
    setConfirmDialogOptions({ open: false, category: null });
  }, []);

  //TODO fix filtering categories
  // Now all included categories are filtered out, but there is a bug when you tries to change category
  // the previous one won't show up on the list
  // const categoriesToBeFilteredOut = useMemo(
  //   () => _.filter(filterOutCategories, (categoryId) => categoryId !== selectedCategory?.id),
  //   [selectedCategory, filterOutCategories, filterOutNestedCategories]
  // );

  const sortedCategories = useMemo(() => {
    return _.sortBy(categoryList, (category) => getCategoryLabel(category));
  }, [categoryList]);

  const filteredTransfersInOrOut = useMemo(() => {
    return includeTransfers
      ? sortedCategories
      : _.filter(sortedCategories, (category) => {
          const isCategoryTransfer = _.includes(category.name, "Transfer to");
          return isTransfer ? isCategoryTransfer : !isCategoryTransfer;
        });
  }, [sortedCategories, isTransfer, includeTransfers]);

  const filterNestedCategories = useMemo(() => {
    return filterOutNestedCategories
      ? _.filter(filteredTransfersInOrOut, (category) => _.isEmpty(category.parent_category))
      : filteredTransfersInOrOut;
  }, [filteredTransfersInOrOut, filterOutNestedCategories]);

  return (
    <React.Fragment>
      <div>
        <Select
          fill={fill}
          query={query}
          disabled={disabled}
          popoverRef={selectRef}
          onQueryChange={setQuery}
          createNewItemFromQuery={isTransfer ? undefined : createItem}
          createNewItemRenderer={renderCreateItemOption}
          itemPredicate={filterCategories}
          items={_.filter(
            filterNestedCategories,
            // @ts-ignore
            (category) => !_.includes(filterOutCategories, category.id)
          )}
          onItemSelect={onSelectCategory}
          // @ts-ignore
          itemRenderer={renderItem}
          noResults={<MenuItem disabled={true} text={t("labels.no_results")} />}
        >
          <Button
            fill={fill}
            text={
              selectedCategory ? (
                <div className="flex flex-row items-center">
                  <EntityIcon icon={selectedCategory.icon} type="category" />
                  <span className="inline-block ml-2">{selectedCategory.name}</span>
                </div>
              ) : isTransfer ? (
                t("actions.choose_account") // looks stupid but it's inline with account selection
              ) : (
                t("actions.choose_category")
              )
            }
            rightIcon="double-caret-vertical"
          />
        </Select>
      </div>
      <Dialog
        isOpen={confirmDialogOptions.open}
        title={t("confirm.new_category.title")}
        icon={IconNames.WARNING_SIGN}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
      >
        <DialogBody>
          <div
            dangerouslySetInnerHTML={{
              __html: t("confirm.new_category.message", {
                categoryName: confirmDialogOptions.category?.name,
              }),
            }}
          />
        </DialogBody>
        <DialogFooter
          actions={
            <ButtonGroup>
              <Button intent={Intent.NONE} text={t("actions.cancel")} onClick={closeDialog} />
              <Button
                intent={Intent.PRIMARY}
                text={t("actions.create")}
                onClick={createNewCategory}
              />
            </ButtonGroup>
          }
        />
      </Dialog>
    </React.Fragment>
  );
};

export default CategorySelect;
