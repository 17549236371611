import { useQuery } from "@apollo/client";
import React from "react";
import { PAYEE_LIST } from "../../graphql/queries/payees";
import PayeeSelect, { IPayeeSelect } from "./PayeeSelect";
import WithLoadingSpinner from "./WithLoadingSpinner";

const PayeeSelectWithQuery = (props: IPayeeSelect) => {
  const { loading, error, data: payees } = useQuery(PAYEE_LIST);
  return (
    <WithLoadingSpinner isLoading={loading}>
      <PayeeSelect {...props} payeesList={payees?.payees} />
    </WithLoadingSpinner>
  );
};

export default PayeeSelectWithQuery;
