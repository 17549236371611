import { Callout, Intent } from "@blueprintjs/core";
import _ from "lodash";
import React, { FC } from "react";
import { IEntry } from "../../types/types";
import FormBuilder from "./FormBuilder";
import entrySchema from "./schemas/entry";
import { useTranslation } from "react-i18next";

interface IEntryForm {
  entry: IEntry;
  onChange: (type: string, value: any | null) => void;
  onApply: (arg0: IEntry) => Promise<any> | void;
  onApplyAndNext: (arg0: IEntry) => void;
  onCancel: () => void;
  formStatus?: string;
}

const EntryForm: FC<IEntryForm> = ({
  entry,
  formStatus,
  onChange,
  onApply,
  onApplyAndNext,
  onCancel,
}) => {
  const fieldsToFilterOut = entry.transfer
    ? ["splitted_entries", "payee", "type"]
    : ["category", "value"];
  const formFields = _.omit(entrySchema.fields, fieldsToFilterOut);
  const formSchema = _.extend({}, entrySchema, { fields: formFields });
  const { t } = useTranslation();

  return (
    <div>
      {formStatus && <Callout intent={Intent.SUCCESS} title={formStatus} />}
      <FormBuilder
        applyLabel={t("labels.save_and_close")}
        withAddNext={true}
        formSchema={formSchema}
        entity={entry}
        onCancel={onCancel}
        onApply={onApply}
        onApplyAndNext={onApplyAndNext}
        onChange={onChange}
        additionalOptions={{
          isTransfer: entry.transfer,
          filterOutCategories: [],
          type: {
            inline: true,
          },
          category: {
            filterOutNestedCategories: false,
          },
          transferSwitch: {
            disabled: !_.isEmpty(entry.id),
            onChange: () => {
              onChange("type", null);
              onChange("category", null);
              onChange("transfer", !entry.transfer);
            },
          },
        }}
      />
    </div>
  );
};

export default EntryForm;
