import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useAuth0, User } from "@auth0/auth0-react";
import { Button, Icon, Intent, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { App as CapApp } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import _ from "lodash";
import React, { PropsWithChildren, ReactNode, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { graphqlUri } from "../settings";
import { useTokenStore } from "../stores/useTokenStore";
import { handleError } from "../utils/utils";
import FakeLoader from "./common/FakeLoader";
import PublicRoutes from "./PublicRoutes";
import WithUserContext from "./WithUserContext";

const httpLink = new HttpLink({
  uri: graphqlUri,
});

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

const getApolloClient = (token: string | null) => {
  if (apolloClient && !_.isEmpty(token)) return apolloClient;
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    queryDeduplication: false,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  });
  return apolloClient;
};

const WithToken = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const { getAccessTokenSilently, isLoading, user, handleRedirectCallback, isAuthenticated } =
    useAuth0();
  const { token, setToken } = useTokenStore((state) => ({
    ...state,
  }));

  const [stateToken, setStateToken] = React.useState<string | null>(null);

  const [appLoading, setAppLoading] = React.useState(false);

  useEffect(() => {
    // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
    CapApp.addListener("appUrlOpen", async ({ url }) => {
      if (url.includes("state") && (url.includes("code") || url.includes("error"))) {
        await handleRedirectCallback(url);
        getToken();
      }
      // No-op on Android
      await Browser.close();
    });
  }, [handleRedirectCallback]);

  const getToken = useCallback(async () => {
    getAccessTokenSilently()
      .then((accessToken) => {
        setToken(accessToken);
        setStateToken(accessToken);
      })
      .catch((err) => {
        err.message !== "Login required" && handleError(err);
      })
      .finally(() => {
        setAppLoading(false);
      });
  }, []);

  useEffect(() => {
    getToken();
  }, []);

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate("/dashboard");
  //   }
  // }, [isAuthenticated]);

  return (
    <React.Fragment>
      {/* <div>
        Token: {(token || "").slice(0, 50)} <br />
        StateToken: {(stateToken || "").slice(0, 50)} <br />
        Loading: {isLoading.toString()} <br />
        AppLoading: {appLoading.toString()} <br />
        isAuthenticated: {isAuthenticated.toString()} <br />
        Location: {window.location.toString()}
      </div> */}
      {!isLoading && !appLoading && isAuthenticated && _.isEmpty(token) && (
        <div className="min-h-screen max-h-screen w-full flex items-center justify-center">
          <NonIdealState
            icon={
              <Icon
                className="cursor-pointer"
                size={56}
                icon={IconNames.REFRESH}
                onClick={getToken}
              />
            }
            title={t("actions.refresh")}
            action={
              <Button
                className="!text-[18px] px-5"
                text={t("actions.reload")}
                intent={Intent.SUCCESS}
                onClick={getToken}
              />
            }
          />
        </div>
      )}
      {_.isEmpty(stateToken) && isLoading ? (
        <FakeLoader isLoading={isLoading} interval={100} />
      ) : !isAuthenticated ? (
        <PublicRoutes />
      ) : (
        stateToken && (
          <Provider token={stateToken} user={user}>
            {children}
          </Provider>
        )
      )}
    </React.Fragment>
  );
};

const Provider = ({
  token,
  user,
  children,
}: PropsWithChildren<{ user: User | undefined; token: string }>) => {
  return (
    <ApolloProvider client={getApolloClient(token)}>
      <WithUserContext authUser={user}>{children}</WithUserContext>
    </ApolloProvider>
  );
};

export default WithToken;
