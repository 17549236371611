import { Callout, DialogBody, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import Papa from "papaparse";
import React, { FC, useRef } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { parseCSV } from "../../utils/importUtils";

type PropertyKey = "date" | "payee" | "income" | "expense" | "description" | "balance";

type Property = {
  columnIndex: number;
  name: string;
};

export type Properties = {
  date?: Property;
  payee?: Property;
  income?: Property;
  expense?: Property;
  description?: Property;
  balance?: Property;
};

const PROPERTIES: (keyof Properties)[] = [
  "date",
  "payee",
  "income",
  "expense",
  "balance",
  "description",
];

export type DropColumnItem = {
  name: string;
  columnIndex: number;
};

type DataDraggableProps = {
  propertyContainers: Properties;
  data: string[][];
  removeFromProperties: (propertyKey: keyof Properties) => () => void;
  handleDrop: (propertyKey: keyof Properties, item: DropColumnItem) => void;
};

const DataDraggable = ({
  data,
  handleDrop,
  propertyContainers,
  removeFromProperties,
}: DataDraggableProps) => {
  const { t } = useTranslation();
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="mt-3 flex flex-row gap-5">
        <div className="flex-1">
          <h3 className="text-center mb-5">{t("labels.budget_containers")}</h3>
          {PROPERTIES.map((propertyKey) => {
            return (
              <PropertyBin
                key={propertyKey}
                onDrop={(item) => handleDrop(propertyKey, item)}
                property={propertyContainers[propertyKey]}
                propertyKey={propertyKey}
                removeFromProperties={removeFromProperties(propertyKey)}
              />
            );
          })}
        </div>
        <div className="flex-1">
          <h3 className="text-center mb-5">{t("labels.csv_columns")}</h3>
          <div>
            {data[0].map((key, idx) => {
              return (
                <CSVColumnBox
                  type={ItemTypes.COLUMN}
                  idx={idx}
                  name={key}
                  key={key}
                  isDropped={Object.values(propertyContainers).some(
                    (property) => property?.name === key
                  )}
                />
              );
            })}
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

const PropertyBin = ({
  property,
  propertyKey,
  onDrop,
  removeFromProperties,
}: {
  property?: Property;
  propertyKey: string;
  onDrop: (item: DropColumnItem) => void;
  removeFromProperties: () => void;
}) => {
  const { t } = useTranslation();
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.COLUMN,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop() && !property,
    }),
  });

  const isActive = isOver && canDrop;
  let backgroundColor = "transparent";
  if (isActive) {
    backgroundColor = "darkgreen";
  } else if (canDrop) {
    backgroundColor = "darkkhaki";
  }

  return (
    <Callout
      compact
      className="mb-2"
      intent={property ? "success" : "none"}
      title={t(`labels.${propertyKey}`)}
    >
      <div ref={drop} style={{ backgroundColor }} className="p-2" data-testid="dustbin">
        <div>
          {property ? (
            <div className="flex flex-row w-full">
              {property.name}
              <Icon icon={IconNames.CROSS} onClick={removeFromProperties} />
            </div>
          ) : isActive ? (
            "Release to drop"
          ) : (
            `Drag a column here to bind to "${propertyKey}"`
          )}
        </div>
      </div>
    </Callout>
  );
};

export interface DraggableColumnProps {
  name: string;
  idx: number;
  type: string;
  isDropped: boolean;
}

const ItemTypes = {
  COLUMN: "column",
};

export const CSVColumnBox: FC<DraggableColumnProps> = function CSVColumnBox({
  idx,
  name,
  type,
  isDropped,
}: DraggableColumnProps) {
  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: { name, columnIndex: idx },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [idx, name, type]
  );

  return (
    <div ref={isDropped ? undefined : drag} className="p-5" style={{ opacity }} data-testid="box">
      {isDropped ? <s>{name}</s> : name}
    </div>
  );
};

type CsvFileLoaderProps = {
  setData: (data: string[][]) => void;
  data?: string[][];
  setPropertyContainers: (propertyContainers: Properties) => void;
  propertyContainers: Properties;
  onDrop: (propertyKey: keyof Properties, item: DropColumnItem) => void;
};

const CsvFileLoader = ({
  setData,
  data,
  setPropertyContainers,
  propertyContainers,
  onDrop,
}: CsvFileLoaderProps) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  return (
    <DialogBody>
      <Callout>
        {_.isEmpty(data) ? (
          <React.Fragment>
            <div onClick={() => fileInputRef.current?.click()} className="cursor-pointer">
              <Icon className="mr-2" icon={IconNames.DOCUMENT} />
              {t("import.select_file_explanation")}
            </div>
            <input
              className="-ml-[1000px] absolute"
              ref={fileInputRef}
              type="file"
              accept=".csv"
              onChange={(e) => {
                const file: File = e.target.files?.[0] as File;
                if (file) {
                  const reader = new FileReader();
                  reader.readAsText(file);
                  reader.onload = (event) => {
                    const csvData = event.target?.result as string;
                    const parsedData = parseCSV(csvData);
                    Papa.parse(parsedData as string, {
                      skipEmptyLines: true,
                      complete: (result) => {
                        const data = (result.data as string[][]).map((d: string[]) => {
                          return _.compact(d);
                        });
                        setData(data);
                      },
                    });
                  };

                  // Papa.parse(file, {
                  //   header: false,
                  //   skipEmptyLines: true,
                  //   complete: (result) => {
                  //     setData(result.data as string[][]);

                  //     console.log(result.data);
                  //   },
                  // });
                }
              }}
            />
          </React.Fragment>
        ) : (
          <div>
            <div className="text-center mb-5">
              <Icon icon={IconNames.INFO_SIGN} className="mr-2" />{" "}
              {t("import.dnd_columns_explanation")}
            </div>
            <DataDraggable
              propertyContainers={propertyContainers}
              handleDrop={onDrop}
              data={data as string[][]}
              removeFromProperties={(propertyKey) => () =>
                setPropertyContainers(_.omit(propertyContainers, propertyKey))}
            />
          </div>
        )}
      </Callout>
    </DialogBody>
  );
};

export default CsvFileLoader;
