import gql from "graphql-tag";

export const CREATE_SPLITTED_ENTRY = gql`
  mutation createSplittedEntry($object: splitted_entries_insert_input!) {
    insert_splitted_entries_one(object: $object) {
      id
      category_id
      value
    }
  }
`;

export const CREATE_SPLITTED_ENTRIES = gql`
  mutation createSplittedEntry($objects: [splitted_entries_insert_input!]!) {
    insert_splitted_entries(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_SPLITTED_ENTRY = gql`
  mutation updateSplittedEntry($id: Int!, $changes: splitted_entries_set_input!) {
    update_splitted_entries_by_pk(pk_columns: { id: $id }, _set: $changes) {
      id
      category_id
      value
    }
  }
`;

export const SOFT_DELETE_SPLITTED_ENTRY = gql`
  mutation softDeleteSplittedEntry($id: Int!, $deleted_at: timestamptz) {
    update_splitted_entries_by_pk(pk_columns: { id: $id }, _set: { deleted_at: $deleted_at }) {
      id
    }
  }
`;

export const DELETE_SPLITTED_ENTRY = gql`
  mutation deleteSplittedEntry($id: Int!) {
    delete_splitted_entries_by_pk(id: $id) {
      id
    }
  }
`;
