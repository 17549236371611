import { useMutation } from "@apollo/client";
import { Button, Card, Classes, Collapse, Dialog, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { FC, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { CREATE_INCOME, SOFT_DELETE_INCOME, UPDATE_INCOME } from "../../graphql/mutations/incomes";
import { INCOMES } from "../../graphql/queries/incomes";
import { IIncome } from "../../types/types";
import { graphQlError, parseDate } from "../../utils/utils";
import { BudgetContext } from "../WithBudgetContext";
import { UserContext } from "../WithUserContext";
import { Notifications } from "../common/notifications";
import IncomeForm from "../forms/IncomeForm";
import IncomesList from "./IncomesList";

const initialIncome = {
  name: "",
  value: 0.0,
  actual_value: 0.0,
};

interface IIncomesProps {
  selectedDate: DateTime;
  incomes: IIncome[];
  totalIncome: number;
  totalRealIncome: number;
}

const Incomes: FC<IIncomesProps> = ({ incomes, selectedDate, totalIncome, totalRealIncome }) => {
  const userData = useContext(UserContext);
  const currentBudget = useContext(BudgetContext);

  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(true);
  const [incomeEntry, setIncomeEntry] = useState<IIncome | null>(null);

  const [createIncome] = useMutation(CREATE_INCOME, {
    refetchQueries: [{ query: INCOMES, variables: { budgetId: currentBudget?.id } }],
  });

  const [updateIncome] = useMutation(UPDATE_INCOME, {
    refetchQueries: [{ query: INCOMES, variables: { budgetId: currentBudget?.id } }],
  });

  const toggleCollapse = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const openFormDialog = useCallback(() => {
    setIncomeEntry(initialIncome as IIncome);
  }, []);

  const closeFormDialog = useCallback(() => {
    setIncomeEntry(null);
  }, []);

  const onChange = (key: string, value: any) => {
    setIncomeEntry(_.extend({}, incomeEntry, { [key]: value }));
  };

  const onDelete = (income: IIncome) => {
    return deleteIncome({
      variables: { id: income.id, deleted_at: selectedDate || DateTime.now() },
    })
      .then(() => {
        Notifications && Notifications.show({ message: "Removed", intent: Intent.WARNING });
      })
      .catch(graphQlError);
  };

  const [deleteIncome] = useMutation(SOFT_DELETE_INCOME, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: INCOMES, variables: { budgetId: currentBudget?.id } }],
  });

  const openEditFormDialog = (income: IIncome) => {
    setIncomeEntry(income);
  };

  const onApply = (income: IIncome) => {
    const entryMonth = parseDate(income.income_date).toFormat("M");
    const selectedMonth = selectedDate.toFormat("M");

    return (
      !income.id
        ? createIncome({
            variables: {
              object: {
                ..._.omit(income, ["__typename"]),
                budget_id: currentBudget?.id,
                user_id: userData?.user_id,
                income_date: selectedDate,
              },
            },
          })
        : selectedMonth !== entryMonth
        ? Promise.all([
            createIncome({
              variables: {
                object: {
                  ..._.omit(income, ["id", "__typename", "created_at", "update_at"]),
                  budget_id: currentBudget?.id,
                  user_id: userData?.user_id,
                  income_date: selectedDate,
                },
              },
            }),
            deleteIncome({ variables: { id: income.id, deleted_at: selectedDate } }),
          ])
        : updateIncome({
            variables: {
              ..._.omit(income, ["__typename"]),
              budget_id: currentBudget?.id,
              user_id: userData?.user_id,
            },
          })
    ).then(closeFormDialog);
  };

  return (
    <Card className="p-1 mb-4">
      <div className={`flex items-center p-1 ${Classes.CALLOUT}`}>
        <h5 className={`${Classes.HEADING} flex-grow`}>
          <div className="flex flex-grow items-center py-3 md:py-2">
            <Icon
              className="mr-2 cursor-pointer"
              onClick={toggleCollapse}
              icon={isOpen ? IconNames.DOUBLE_CHEVRON_UP : IconNames.DOUBLE_CHEVRON_DOWN}
            />
            <div className="text-base">{t("incomes.planned")}</div>
          </div>
        </h5>
        <div>
          <Button
            className="action--small text-sm"
            minimal
            outlined
            icon={IconNames.ADD}
            intent={Intent.PRIMARY}
            onClick={openFormDialog}
          >
            {t("actions.add_income")}
          </Button>
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        <IncomesList
          incomes={incomes}
          onEdit={openEditFormDialog}
          onDelete={onDelete}
          totalIncome={totalIncome}
          totalRealIncome={totalRealIncome}
          selectedDate={selectedDate}
        />
      </Collapse>
      <Dialog
        isOpen={!_.isEmpty(incomeEntry)}
        onClose={closeFormDialog}
        title={
          incomeEntry?.id
            ? t("incomes.edit_income", { name: incomeEntry?.name || "" })
            : t("incomes.add_income")
        }
      >
        <IncomeForm
          income={incomeEntry as IIncome}
          onChange={onChange}
          onCancel={closeFormDialog}
          onApply={onApply}
        />
      </Dialog>
    </Card>
  );
};

export default Incomes;
