import { useQuery } from "@apollo/client";
import React, { useContext } from "react";
import { CATEGORIES_LIST } from "../../graphql/queries/categories";
import { BudgetContext } from "../WithBudgetContext";
import CategorySelect, { ICategorySelect } from "./CategoriesSelect";
import WithLoadingSpinner from "./WithLoadingSpinner";

const CategorySelectWithQuery = (props: ICategorySelect) => {
  const currentBudget = useContext(BudgetContext);
  const { data, loading } = useQuery(CATEGORIES_LIST, {
    variables: { budgetId: currentBudget?.id },
  });

  return (
    <WithLoadingSpinner isLoading={loading}>
      <CategorySelect {...props} categoryList={data?.categories} />
    </WithLoadingSpinner>
  );
};

export default CategorySelectWithQuery;
