import { Properties } from "../components/import/CsvFileLoader";
import _ from "lodash";
import { ICategory, IEntry, IPayee } from "../types/types";
import { parseDate, prepareNewDate } from "./utils";

export type PreparedEntry = {
  [key: string]: string | null | undefined | ICategory | IPayee;
  _orginalPayee?: string;
  amount: string;
  balance?: string | null;
  category: ICategory | null;
  date: string;
  description: string | null;
  payee: IPayee | null;
};

export type FinalEntryToInsert = {
  amount: number;
  balance: number;
  category_id: number;
  category?: ICategory;
  date: string;
  description: string;
  checked: boolean;
  payee_id: number;
  payee?: IPayee | undefined;
  [key: string]: number | string | boolean | ICategory | IPayee | undefined;
};

export const parseDataForImportPreview = (
  data: string[][],
  propertyContainers: Properties
): PreparedEntry[] => {
  return _.reduce(
    data,
    (acc: PreparedEntry[], row, idx) => {
      const payee = null;
      const _orginalPayee = row[propertyContainers.payee?.columnIndex as number];
      const date = parseDate(row[propertyContainers.date?.columnIndex as number])
        .plus({ minutes: idx + 1 })
        .toISO();
      const balance = row[propertyContainers.balance?.columnIndex as number];
      const amount =
        row[propertyContainers.income?.columnIndex as number] ||
        row[propertyContainers.expense?.columnIndex as number];
      const category = null;
      const description = row[propertyContainers.description?.columnIndex as number];

      if (date && amount) {
        acc.push({
          _orginalPayee,
          amount,
          balance,
          category,
          date,
          description,
          payee,
        });
      }

      return acc;
    },
    []
  );
};

export const prepareDataForFinalCheck = (
  data: PreparedEntry[],
  categories: ICategory[],
  payees: IPayee[]
): FinalEntryToInsert[] => {
  //@ts-ignore
  return data.length > 0
    ? data.map((entry, idx) => {
        return {
          ...entry,

          category: entry.category || categories[0],
          payee: entry.payee || payees[0],
          category_id: entry.category?.id || categories[0].id,
          payee_id: entry.payee?.id || payees[0].id,
          amount: parseFloat(entry.amount),
          balance: entry.balance ? parseFloat(entry.balance) : 0,
          checked: true,
        };
      })
    : [];
};

export const prepareEntryForInsert = (
  entryToBe: FinalEntryToInsert,
  account_id: number,
  user_id: string,
  budget_id: number
): IEntry => {
  return {
    value: entryToBe.amount,
    balance: entryToBe.balance,
    account_id,
    budget_id,
    user_id,
    transfer: false,
    type: entryToBe.amount < 0 ? "expense" : "income",
    category_id: entryToBe.category_id,
    date: prepareNewDate(entryToBe.date),
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    description: entryToBe.description,
    payee_id: entryToBe.payee_id,
    splitted_entries: [
      {
        value: entryToBe.amount,
        category_id: entryToBe.category_id,
      },
    ],
  };
};

// Function to detect delimiter dynamically
function detectDelimiter(firstLine: string) {
  const delimiters = [",", ";"];
  let maxCount = 0;
  let detectedDelimiter = null;

  delimiters.forEach((delimiter) => {
    const count = (firstLine.match(new RegExp(delimiter, "g")) || []).length;
    if (count > maxCount) {
      maxCount = count;
      detectedDelimiter = delimiter;
    }
  });

  return detectedDelimiter || ","; // Default to ',' if no delimiter is found
}

// Function to find the start of the table dynamically
function findTableStart(lines: string[], delimiter: string, delimiterCount: number) {
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    const columns = line.split(delimiter);
    if (columns.length > delimiterCount - 1) {
      // Adjust threshold based on expected structure
      return i;
    }
  }
  return -1; // Return -1 if no table header is found
}

// Function to parse the CSV file and extract the table
export function parseCSV(csvContent: string) {
  // Split content into lines
  const lines = csvContent.split("\n").map((line) => line.trim());

  if (lines.length === 0) {
    console.error("Error: CSV file is empty.");
    return [];
  }
  const delimiter = detectDelimiter(lines[0]);

  const delimiterDetector = lines.reduce(
    (acc, line, idx) => {
      const delimeterCount = (line.match(new RegExp(delimiter, "g")) || []).length;
      if (delimeterCount > acc.delimeterCount) {
        return {
          line: idx,
          delimeterCount,
        };
      }
      return acc;
    },
    {
      line: 0,
      delimeterCount: 0,
    }
  );

  // Detect delimiter from the first line
  if (!delimiter) {
    console.error("Error: Could not detect delimiter.");
    return [];
  }

  // Find where the table starts
  const tableStartRow = findTableStart(lines, delimiter, delimiterDetector.delimeterCount);

  if (tableStartRow === -1) {
    console.error("Error: Could not find the start of the table.");
    return [];
  }

  // Extract headers and rows starting from the detected header row
  const headers = lines[tableStartRow].split(delimiter).map((header) => header.trim());
  const rows = lines.slice(tableStartRow + 1);

  const cleanedLines = lines.slice(tableStartRow);
  return cleanedLines.join("\n");
  // Build an array of objects representing the table
  const tableData = rows.map((row) =>
    headers.reduce((obj: { [key: string]: string }, header, index) => {
      obj[header] = row[index] || ""; // Handle missing values gracefully
      return obj;
    }, {})
  );
}
