import { Button, Classes, Colors, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IEntry, ISingleEntry } from "../../types/types";
import CategorySelectWithQuery from "../common/CategorySelectWithQuery";
import MoneyValue from "../common/MoneyValue";
import { ValueInput } from "./FormBuilder";
import { TValidationResults, getValidationErrorMessage } from "./validators";

interface IMultiEntries {
  entity: IEntry;
  onChange: (key: string, value: any) => void;
  field: any;
  validationResults: TValidationResults;
}
const VALUE_KEY = "splitted_entries";

const prepareMultiEntries = (entity: IEntry) => {
  return _.isEmpty(entity[VALUE_KEY])
    ? [
        {
          value: 0,
        } as ISingleEntry,
      ]
    : (entity[VALUE_KEY] as ISingleEntry[]);
};

const MultiEntries = ({ entity, onChange, field, validationResults }: IMultiEntries) => {
  const [entries, setEntries] = useState(prepareMultiEntries(entity) as ISingleEntry[]);

  useEffect(() => {
    setEntries(prepareMultiEntries(entity) as ISingleEntry[]);
  }, [entity]);

  const { t } = useTranslation();

  const onAdd = useCallback(() => {
    setEntries((entries) => entries.concat({ value: 0 } as ISingleEntry));
    onChange(VALUE_KEY, entries);
  }, [onChange, entries]);

  const onRemove = useCallback(
    (index: number) => () => {
      setEntries((entries) => {
        const newEntries = [
          ...entries.slice(0, index),
          _.extend({}, entries[index], { deleted: true }),
          ...entries.slice(index + 1),
        ];
        onChange(VALUE_KEY, newEntries);
        return newEntries;
      });
    },
    [onChange]
  );

  const onRevertDelete = useCallback(
    (index: number) => () => {
      setEntries((entries) => {
        const newEntries = [
          ...entries.slice(0, index),
          _.extend({}, _.omit(entries[index], "deleted")),
          ...entries.slice(index + 1),
        ];
        onChange(VALUE_KEY, newEntries);
        return newEntries;
      });
    },
    [onChange]
  );

  const onChangeEntry = useCallback(
    (index: number, key: string) => (value: any) => {
      setEntries((entries) => {
        const newEntries = [
          ...entries.slice(0, index),
          _.extend({}, entries[index], { [key]: value }),
          ...entries.slice(index + 1),
        ];
        onChange(VALUE_KEY, newEntries);
        return newEntries;
      });
    },
    [onChange]
  );

  const totalValue = useMemo(() => {
    return _.reduce(
      entries,
      (sum, entry) => (sum += entry.deleted ? 0 : +(_.isNaN(entry.value) ? 0 : entry.value)),
      0
    );
  }, [entries]);

  return (
    <div>
      <div className={[Classes.FORM_HELPER_TEXT].join(" ")} style={{ color: Colors.RED2 }}>
        {getValidationErrorMessage(validationResults, "splitted_entries")}
      </div>
      {_.map(entries, (splittedEntry, index) => {
        return (
          <div
            key={index}
            className={`flex multiinsert-field mb-1 ${splittedEntry.deleted ? "bg-red-100" : ""}`}
          >
            <div className="flex flex-grow">
              <CategorySelectWithQuery
                includeTransfers={false}
                isTransfer={false}
                filterOutCategories={[]}
                filterOutNestedCategories={false}
                onChange={onChangeEntry(index, "category")}
                initialCategory={splittedEntry["category"]}
              />
            </div>
            <div className="flex">
              <div className="inline-block ml-1" style={{ minWidth: "75px" }}>
                <ValueInput
                  value={splittedEntry["value"]}
                  entityKey={VALUE_KEY}
                  onChange={(_, value) => {
                    onChangeEntry(index, "value")(value);
                  }}
                />
              </div>
              {entries.length > 1 && (
                <Button
                  small
                  minimal
                  title={splittedEntry.deleted ? t("actions.revert") : t("actions.delete")}
                  intent={splittedEntry.deleted ? Intent.PRIMARY : Intent.DANGER}
                  icon={splittedEntry.deleted ? IconNames.TICK : IconNames.REMOVE}
                  onClick={splittedEntry.deleted ? onRevertDelete(index) : onRemove(index)}
                />
              )}
            </div>
          </div>
        );
      })}
      <div className="flex flex-row mt-2 items-center">
        <div className="flex-grow">
          <Button icon={IconNames.PLUS} intent={Intent.SUCCESS} onClick={onAdd}>
            {t("labels.add_another")}
          </Button>
        </div>
        <div className="text-right mr-10 flex flex-row">
          <span className="inline-block mr-1">{t("labels.total")}:</span>{" "}
          <MoneyValue value={totalValue} />
        </div>
      </div>
    </div>
  );
};

export default MultiEntries;
