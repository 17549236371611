import { useQuery } from "@apollo/client";
import { User } from "@auth0/auth0-react";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { USER } from "../graphql/queries/user";
import { IUser } from "../types/types";
import { getUserSettings } from "../utils/utils";
import WithLoadingSpinner from "./common/WithLoadingSpinner";

interface Props {
  authUser: User | undefined;
  children: ReactNode;
}

export const UserContext = React.createContext<IUser | undefined>(undefined);

const WithUserContext = ({ authUser, children }: Props) => {
  const { data, loading } = useQuery(USER, { variables: { userId: authUser?.sub } });

  const userData = useMemo((): IUser => {
    const maybeUser = data?.users_by_pk as IUser;
    return _.extend({}, maybeUser, {
      settings: getUserSettings(maybeUser?.settings || "{}"),
    }) as IUser;
  }, [data]);

  // This is a workaround for the issue with React 18 Suspense
  // Actuall I don't know WTF, but it works for error with missing budgetId in CATEGORIES query
  _.defer(() => _.noop);

  return (
    <WithLoadingSpinner isLoading={loading}>
      {userData && <UserContext.Provider value={userData}>{children}</UserContext.Provider>}
    </WithLoadingSpinner>
  );
};

export default WithUserContext;
