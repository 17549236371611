import { useMutation, useQuery } from "@apollo/client";
import { Button, Dialog, HTMLTable, Icon, Intent, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { t } from "i18next";
import _ from "lodash";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useCreateCategory, useDeleteCategory } from "../../graphql/hooks/category";
import { UPDATE_CATEGORY } from "../../graphql/mutations/category";
import { CATEGORIES } from "../../graphql/queries/categories";
import { pageTitle } from "../../settings";
import { ICategory } from "../../types/types";
import { ENTRY_TYPES } from "../../utils/constants";
import { UserContext } from "../WithUserContext";
import Actions from "../common/Actions";
import { ConfirmDialog } from "../common/ConfirmDialog";
import DateComponent from "../common/Date";
import EntityIcon from "../common/EntityIcon";
import GraphQlError from "../common/GraphQlError";
import InternalLink from "../common/InternalLink";
import MoneyValue from "../common/MoneyValue";
import TableHeader from "../common/TableHeader";
import WithLoadingSpinner from "../common/WithLoadingSpinner";
import CategoryForm from "../forms/CategoryForm";
import { BudgetContext } from "../WithBudgetContext";

export default function Categories() {
  const { t } = useTranslation();

  const userData = useContext(UserContext);
  const currentBudget = useContext(BudgetContext);

  console.log({ currentBudget });

  const { data, loading, error } = useQuery(CATEGORIES, {
    variables: { budgetId: currentBudget?.id },
  });
  const { categories } = data || {};

  const [createCategory] = useCreateCategory();
  const [deleteCategory] = useDeleteCategory();
  const [updateCategory] = useMutation(UPDATE_CATEGORY);
  const [categoryEntry, setCategoryEntry] = useState<ICategory | null>(null);

  const createNewCategory = useCallback(() => setCategoryEntry({ name: "" }), []);
  const closeDialog = useCallback(() => setCategoryEntry(null), []);
  const onEdit = useCallback((category: ICategory) => setCategoryEntry(category), []);
  const onDelete = useCallback(
    (categoryId: number) => deleteCategory({ variables: { id: categoryId } }),
    [deleteCategory]
  );
  const onChange = (key: string, value: any): void => {
    setCategoryEntry(_.extend({}, categoryEntry, { [key]: value }));
  };

  const onSave = (category: ICategory) => {
    const categoryObject = {
      ..._.omit(category, ["__typename"]),
      budget_id: currentBudget?.id,
      user_id: userData?.user_id,
    };

    return (
      !category.id
        ? createCategory({
            variables: {
              object: categoryObject,
            },
          })
        : updateCategory({
            variables: categoryObject,
          })
    ).then(closeDialog);
  };

  return (
    <WithLoadingSpinner isLoading={loading}>
      <Helmet>
        <title>Categories - {pageTitle}</title>
      </Helmet>
      <GraphQlError error={error}>
        <Dialog
          usePortal
          isOpen={!_.isEmpty(categoryEntry)}
          onClose={closeDialog}
          title={
            categoryEntry?.id
              ? t("categories.edit_category", { name: categoryEntry?.name })
              : t("categories.new_category")
          }
        >
          <CategoryForm
            category={categoryEntry as ICategory}
            onChange={onChange}
            onApply={onSave}
            onCancel={closeDialog}
          />
        </Dialog>
        {_.isEmpty(categories) ? (
          <NonIdealState
            icon="search"
            title={t("categories.no_categories")}
            action={
              <Button
                text={t("categories.new_category")}
                intent={Intent.PRIMARY}
                onClick={createNewCategory}
              />
            }
          />
        ) : (
          <div>
            <TableHeader
              leftElement={<div></div>}
              rightElement={
                <Button
                  icon={IconNames.ADD}
                  text={t("categories.new_category")}
                  onClick={createNewCategory}
                />
              }
            />

            <HTMLTable
              bordered
              compact
              striped
              className="entries-table w-full max-w-full table-fixed dark:text-gray-100"
            >
              <thead>
                <tr>
                  <th>{t("labels.category")}</th>
                  <th className="budget-table-actions" />
                </tr>
              </thead>
              <tbody>
                {_.map(categories, (category) => (
                  <Category
                    key={category.id}
                    category={category}
                    onDelete={onDelete}
                    onEdit={onEdit}
                  />
                ))}
              </tbody>
            </HTMLTable>
          </div>
        )}
      </GraphQlError>
    </WithLoadingSpinner>
  );
}

export const Category = ({
  category,
  onEdit,
  onDelete,
}: {
  category: ICategory;
  onEdit: (category: ICategory) => void;
  onDelete: (id: number) => void;
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const openDeleteDialog = useCallback(() => {
    setDeleteDialogOpen(true);
  }, []);

  const closeDeleteDialog = useCallback(() => {
    setDeleteDialogOpen(false);
  }, []);

  const isTransfer = useMemo(() => {
    return category.name.toLowerCase().indexOf("transfer") !== -1;
  }, [category]);

  return (
    <tr key={category.id}>
      <td>
        <div className="text-left flex flex-row items-center">
          {isTransfer ? (
            <Icon
              className="mr-2"
              icon={
                isTransfer
                  ? IconNames.CHANGES
                  : category.category_type === ENTRY_TYPES.INCOME
                  ? IconNames.IMPORT
                  : IconNames.EXPORT
              }
            />
          ) : (
            <EntityIcon type="category" icon={category.icon} />
          )}{" "}
          <InternalLink className="ml-2" to={`/categories/${category.id}`}>
            {category.name}
          </InternalLink>
        </div>
      </td>
      <td className="budget-table-actions">
        <Actions
          entry={category}
          onDelete={openDeleteDialog}
          onEdit={() => onEdit(category)}
          editDisabled={isTransfer}
          deleteDisabled={isTransfer}
        />
        {/* <Button icon={IconNames.EDIT} onClick={() => onEdit(category)} disabled={isTransfer} />
        <Button icon={IconNames.TRASH} onClick={openDeleteDialog} disabled={isTransfer} /> */}
        <ConfirmDialog
          icon={IconNames.WARNING_SIGN}
          title={`${t("warnings.delete_category_warning")}`}
          message={
            <div>
              {`${t("warnings.remove_this_category_confirm")}`}
              <div>
                {`${t("categories.category_name")}`}: <b>{category.name}</b>
              </div>
              {!_.isEmpty(category.entries) && (
                <div>
                  <div className="mt-2">{`${t("warnings.following_entries_will_be_removed")}`}</div>
                  <div>
                    {_.map(category.entries, (entry) => {
                      return (
                        <div key={entry.id} className="flex flex-row">
                          <div className="mr-2">
                            <DateComponent date={entry.date ?? Date.now().toString()} />
                          </div>
                          <div className="mr-2">
                            <MoneyValue value={entry.value ?? 0} />
                          </div>
                          <div>{entry.account?.name}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          }
          onCancel={closeDeleteDialog}
          isOpen={deleteDialogOpen}
          onApply={() => {
            onDelete(category.id as number);
            closeDeleteDialog();
          }}
        />
      </td>
    </tr>
  );
};
