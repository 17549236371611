import React from "react";
import { Trans } from "react-i18next";
import { x, instagram, facebook } from "../../settings";
import FacebookIcon from "./FacebookIcon";
import InstagramIcon from "./InstagramIcon";
import TwitterIcon from "./TwitterIcon";

const SocialMediaLinks = () => {
  return (
    <div className="flex items-center gap-10 text-xl">
      <Trans i18nKey="main_page.contact_form.social_media">
        Prefer social media? Reach out to us on:
      </Trans>
      <div className="social-links">
        <a href={x} target="_blank" rel="noopener noreferrer">
          <TwitterIcon size={50} fillColor="#000" />
        </a>
        <a href={instagram} target="_blank" rel="noopener noreferrer">
          <InstagramIcon size={50} fillColor="#000" />
        </a>
        <a href={facebook} target="_blank" rel="noopener noreferrer">
          <FacebookIcon size={50} fillColor="#000" />
        </a>
      </div>
    </div>
  );
};

export default SocialMediaLinks;
