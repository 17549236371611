import { Button, DialogFooter, Intent } from "@blueprintjs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface IDialogFooterProps {
  onCancel:
    | (((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) &
        React.MouseEventHandler<HTMLButtonElement>)
    | undefined;
  onApply:
    | (((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) &
        React.MouseEventHandler<HTMLButtonElement>)
    | undefined;
  onApplyAndNext?:
    | (((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) &
        React.MouseEventHandler<HTMLButtonElement>)
    | undefined;
  cancelLabel?: string;
  applyLabel?: string;
  applyAndAddNextLabel?: string;
  applyEnabled?: boolean;
  saving?: boolean;
  withAddNext?: boolean;
}

const CustomDialogFooter: FC<IDialogFooterProps> = ({
  onCancel,
  onApply,
  onApplyAndNext,
  cancelLabel,
  applyLabel,
  applyAndAddNextLabel,
  applyEnabled,
  saving,
  withAddNext = false,
}) => {
  const { t } = useTranslation();
  return (
    <DialogFooter
      actions={
        <React.Fragment>
          <Button onClick={onCancel}>{cancelLabel || t("labels.cancel")}</Button>
          <Button
            disabled={!applyEnabled || saving}
            loading={saving}
            type="submit"
            intent={Intent.PRIMARY}
            onClick={onApply}
          >
            {applyLabel || t("labels.apply")}
          </Button>
          {withAddNext && onApplyAndNext && (
            <Button
              disabled={!applyEnabled || saving}
              loading={saving}
              type="submit"
              intent={Intent.SUCCESS}
              onClick={onApplyAndNext}
            >
              {applyAndAddNextLabel || t("labels.save_and_add_next")}
            </Button>
          )}
        </React.Fragment>
      }
    ></DialogFooter>
  );
};

export default CustomDialogFooter;
