import gql from "graphql-tag";

export const CREATE_BUDGET = gql`
  mutation createBudget($object: budgets_insert_input!) {
    insert_budgets_one(object: $object) {
      id
    }
  }
`;

export const CREATE_BUDGET_LINK = gql`
  mutation createBudgetLink($object: budget_users_connector_insert_input!) {
    insert_budget_users_connector_one(object: $object) {
      id
      budget_id
      user_id
    }
  }
`;

// export const REMOVE_BUDGET_LINK = gql`
//   mutation deleteBudgetLink($id: Int!) {
//     delete_budget_users_connector_by_pk(id: $id)
//   }
// `;

export const REMOVE_BUDGET_LINK = gql`
  mutation deleteBudgetLink($budgetId: Int!, $userId: String!) {
    delete_budget_users_connector(
      where: { _and: { budget_id: { _eq: $budgetId }, user_id: { _eq: $userId } } }
    ) {
      affected_rows
    }
  }
`;

export const REMOVE_BUDGET_LINKS = gql`
  mutation deleteBudgetLinks($budgetId: Int!) {
    delete_budget_users_connector(where: { budget_id: { _eq: $budgetId } }) {
      affected_rows
    }
  }
`;

// export const UPDATE_BUDGET = gql`
//   mutation updateBudget($id: Int!, $name: String) {
//     update_budgets_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
//       id
//       name
//     }
//   }
// `;

export const UPDATE_BUDGET = gql`
  mutation updateBudget($id: Int!, $changes: budgets_set_input!) {
    update_budgets_by_pk(pk_columns: { id: $id }, _set: $changes) {
      id
      name
    }
  }
`;

export const DELETE_BUDGET = gql`
  mutation deleteBudget($id: Int!) {
    delete_budgets_by_pk(id: $id) {
      id
    }
  }
`;

export const SOFT_DELETE_BUDGET = gql`
  mutation softDeleteBudget($id: Int!) {
    update_budgets_by_pk(pk_columns: { id: $id }, _set: { soft_deleted_at: "now()" }) {
      id
    }
  }
`;
