import { useAuth0 } from "@auth0/auth0-react";
import { Button, Classes, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";
import pcTabletPhoneMock from "../assets/images/pc-tablet-phone-mockup.png";
import budgetSamples from "../assets/images/sample-budgets-en-2.png";
import Affiliations from "./common/Affiliations";
import { ContactForm } from "./common/ContactForm";
import Quotes from "./common/Quotes";
import SocialMediaLinks from "./common/SocialMediaLinks";
import Footer from "./Footer";

const Home = () => {
  const { t } = useTranslation();
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  return (
    <div>
      <div className="home-page">
        <div className="inner-content h-full">
          <div className="flex flex-row item-center h-full">
            <div className="flex flex-col justify-center h-full max-w-7xl mx-auto px-3 text-2xl">
              <div>
                <h1 className="text-white mb-12 text-center font-sans font-semibold text-shadow">
                  <div className="text-4xl md:text-[96px]">{t("labels.app")}</div>
                  <div className="text-9xl md:text-[156px]">4</div>
                  <div className="text-4xl md:text-[96px]">{t("labels.you")}</div>
                </h1>
                <h2 className="text-white mb-12 text-center font-sans md:text-12xl font-semibold text-shadow">
                  {t("app.catchphrase")}
                </h2>
              </div>
              {!isAuthenticated && (
                <Button
                  rightIcon={<Icon className="ml-2" icon={IconNames.CHART} size={40} />}
                  className="!text-xl !md:text-4xl !px-6 !py-3 !md:px-32 !md:py-5 rounded shadowtext-lg font-semibold mx-auto hover:opacity-75 flex flex-row items-center"
                  onClick={() => loginWithRedirect()}
                  intent={Intent.PRIMARY}
                >
                  {t("actions.take_control_of_your_finances")}
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="light x1"></div>
        <div className="light x2"></div>
        <div className="light x3"></div>
        <div className="light x4"></div>
        <div className="light x5"></div>
        <div className="light x6"></div>
        <div className="light x7"></div>
        <div className="light x8"></div>
        <div className="light x9"></div>
      </div>
      <div className="max-w-7xl mx-auto my-12 px-4">
        <MainPageBlock title={t("main_page.who_is_this_for.title")}>
          <div className="md:flex flex-row items-center">
            <div className="flex-1 relative h-full">
              <div
                className="w-full h-full absolute top-0 left-0"
                style={{
                  background:
                    "linear-gradient(0deg, rgba(255,255,255,1) 1%, rgba(255,255,255,0) 100%)",
                }}
              />
              <img className="w-full" src={pcTabletPhoneMock} alt="" />
            </div>
            <div className="flex-1 text-xl">
              <Trans i18nKey="main_page.who_is_this_for.text">
                <div>
                  <h3 className="bp5-heading">
                    Unlock Your Financial Potential with Budget 4 You!
                  </h3>
                  <div className="my-4">
                    Take charge of your finances effortlessly with our intuitive home budgeting
                    solution.
                  </div>
                  <div>
                    Our user-friendly tools empower you to:
                    <div className="ml-4 my-2">Set and achieve financial goals.</div>
                    <div className="ml-4 my-2">Make smarter money decisions.</div>
                    <div className="ml-4 my-2">Track expenses with ease.</div>
                  </div>
                  <div>
                    Start your journey to financial freedom today – absolutely{" "}
                    <strong>FREE!</strong>
                  </div>
                  <ul className="my-5 font-semibold">
                    <li>✅ Intuitive interface for easy budget management</li>
                    <li>✅ Personalized financial insights and goal-setting</li>
                    <li>✅ Secure, real-time tracking of expenses and income</li>
                    <li>✅ Responsive design for seamless use on any device</li>
                    <li>✅ Install as a Progressive Web App for quick access</li>
                    <li>✅ Android app coming soon for enhanced mobile experience</li>
                  </ul>
                  <div>
                    <h3 className="bp5-heading">
                      Don't wait – transform your financial future now!
                    </h3>
                  </div>
                </div>
              </Trans>
            </div>
          </div>
        </MainPageBlock>
        <MainPageBlock title={t("main_page.how_does_it_work.title")}>
          <div className="flex flex-row-reverse mb-12">
            <div className="hidden md:flex flex-grow flex-1 max-w-md ml-12">
              <img className="w-full" src={budgetSamples} alt="" />
            </div>
            <div className="flex-grow flex-1 text-xl">
              <Trans i18nKey="main_page.how_does_it_work.text">
                <div className="">
                  <h3 className={`${Classes.HEADING} !mt-12 !mb-4`}>1. Create Your Budget 💰</h3>
                  <h4>Take the first step towards financial freedom:</h4>
                  <div>List all income sources (salary, freelance work, etc.)</div>
                  <div>Categorize expenses:</div>
                  <ul className="my-3 list-disc ml-6">
                    <li className="my-1">Fixed costs: rent/mortgage, utilities, insurance</li>
                    <li className="my-1">Variable costs: groceries, entertainment, dining out</li>
                  </ul>
                  <h4>Be honest about spending habits for an accurate picture</h4>
                  <div>💡 Pro Tip: Start small! Even a basic budget can make a big difference.</div>
                  <h3 className={`${Classes.HEADING} !mt-12 !mb-4`}>2. Track Your Expenses 📊</h3>
                  <h4>Gain control of your spending:</h4>
                  <ul className="my-3 list-disc ml-6">
                    <li className="my-1">Record every purchase, big or small</li>
                    <li className="my-1">Easily categorize expenses on the go</li>
                    <li className="my-1">Use our intuitive app interface for quick entries</li>
                  </ul>
                  <div>
                    🚀 Bonus: Our smart categorization suggests expense types based on your habits!
                  </div>
                  <h3 className={`${Classes.HEADING} !mt-12 !mb-4`}>
                    3. Unlock Powerful Insights 🔍
                  </h3>
                  <div>Make informed financial decisions:</div>
                  <ul className="my-3 list-disc ml-6">
                    <li className="my-1">Analyze spending trends over time</li>
                    <li className="my-1">View detailed expense breakdowns</li>
                    <li className="my-1">Identify areas to cut costs</li>
                    <li className="my-1">Explore easy-to-understand visualizations and graphs</li>
                  </ul>
                  <div>
                    ✨ Feature Spotlight: Our AI-powered assistant offers personalized savings tips
                    based on your unique spending patterns.
                  </div>
                  <h3 className={`${Classes.HEADING} !mt-12 !mb-4`}>
                    Remember, Budget 4 You isn't just an app – it's your partner in achieving
                    financial success. Start your journey today!
                  </h3>
                </div>
              </Trans>
            </div>
          </div>
        </MainPageBlock>
        <MainPageBlock>
          <Quotes />
          <Affiliations />
        </MainPageBlock>
        <MainPageBlock title={t("main_page.contact_form.title")}>
          <div className="flex flex-col md:flex-row gap-12">
            <div className="flex-1">
              <div className="mb-6 text-xl">
                <Trans i18nKey="main_page.contact_form.text">
                  Whether you're curious about our features, need assistance with your account, or
                  just want to share your thoughts, we'd love to hear from you.
                  <br /> Your feedback helps us improve Budget 4 You and provide the best possible
                  experience for our users.
                </Trans>
              </div>
              <div className="hidden md:block">
                <SocialMediaLinks />
              </div>
            </div>
            <div className="flex-1">
              <ContactForm />
            </div>
            <div className="md:hidden block">
              <SocialMediaLinks />
            </div>
          </div>
        </MainPageBlock>
      </div>

      <Footer />
    </div>
  );
};

export default Home;

const MainPageBlock = ({
  title,
  children,
  icon,
}: {
  title?: ReactNode;
  icon?: ReactNode | null;
  children: ReactNode;
}) => (
  <div className="my-20 min-h-[100vh] flex flex-col justify-center">
    {title && (
      <h1 className="text-2xl md:text-4xl mb-4 font-semibold flex flex-row items-center">
        {icon || <Icon className="mr-4 mb-1" icon={IconNames.INFO_SIGN} size={32} />}
        {title}
      </h1>
    )}
    <div>{children}</div>
  </div>
);
