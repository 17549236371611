import _ from "lodash";
import React, { ReactNode, useContext } from "react";
import { IBudget } from "../types/types";
import CreateBudget from "./budget/CreateBudget";
import Subscriptions from "./Subscriptions";
import { useSubscriptionData } from "./useSubscriptionData";
import { UserContext } from "./WithUserContext";
import { useLocation } from "react-router-dom";
import ContentWithFooter from "./layouts/ContentWithFooter";
import { getUserSettings } from "../utils/utils";

interface Props {
  children: ReactNode;
}

export const BudgetContext = React.createContext<IBudget | undefined>(undefined);
//TODO this needs to be changed

const WithBudgetContext = ({ children }: Props) => {
  const userData = useContext(UserContext);
  const settings = getUserSettings(userData?.settings || "{}");
  const { hasValidSubscription } = useSubscriptionData();

  const currentBudget = settings.current_budget_id
    ? _.find(
        userData?.budget_users_connectors,
        (buc) => buc.budget.id === settings.current_budget_id
      )?.budget
    : _.first(userData?.budget_users_connectors)?.budget;

  const location = useLocation();
  switch (location.pathname) {
    case "/contact":
    case "/changelog":
    case "/roadmap":
    case "/privacy-policy":
    case "/pricing":
      return null;
  }

  return currentBudget && hasValidSubscription ? (
    <BudgetContext.Provider value={currentBudget}>{children}</BudgetContext.Provider>
  ) : (
    <ContentWithFooter>
      {hasValidSubscription ? <CreateBudget /> : <Subscriptions />}
    </ContentWithFooter>
  );
};

export default WithBudgetContext;
