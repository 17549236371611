import React from "react";
const FacebookIcon = ({ size = 24, fillColor = "#fff" }: { size?: number; fillColor?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    width={size}
    height={size}
    viewBox="0 0 256 256"
    xmlSpace="preserve"
    fill={fillColor}
  >
    <defs></defs>
    <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
      <path
        d="M 83.254 90 H 59.253 c -1.104 0 -2 -0.896 -2 -2 V 54.696 c 0 -1.104 0.896 -2 2 -2 h 9.42 l 1.158 -8.979 H 59.253 c -1.104 0 -2 -0.896 -2 -2 v -8.286 c 0 -3.571 0.874 -8.319 8.432 -8.319 l 4.873 -0.002 v -7.801 c -1.871 -0.154 -4.797 -0.32 -8.015 -0.32 c -9.2 0 -14.693 5.666 -14.693 15.156 v 9.572 c 0 1.104 -0.896 2 -2 2 h -9.208 v 8.979 h 9.208 c 1.104 0 2 0.896 2 2 V 88 c 0 1.104 -0.896 2 -2 2 H 6.747 C 3.027 90 0 86.974 0 83.253 V 6.747 C 0 3.026 3.027 0 6.747 0 h 76.507 C 86.974 0 90 3.026 90 6.747 v 76.506 C 90 86.974 86.974 90 83.254 90 z M 61.253 86 h 22.001 C 84.769 86 86 84.768 86 83.253 V 6.747 C 86 5.232 84.769 4 83.254 4 H 6.747 C 5.232 4 4 5.232 4 6.747 v 76.506 C 4 84.768 5.232 86 6.747 86 H 43.85 V 56.696 h -9.208 c -1.104 0 -2 -0.896 -2 -2 V 41.717 c 0 -1.104 0.896 -2 2 -2 h 9.208 v -7.572 c 0 -11.816 7.163 -19.156 18.693 -19.156 c 4.763 0 8.981 0.356 10.278 0.529 c 0.994 0.132 1.736 0.98 1.736 1.982 v 11.608 c 0 1.104 -0.895 2 -1.999 2 l -6.873 0.003 c -3.995 0 -4.433 1.383 -4.433 4.319 v 6.286 h 10.853 c 0.575 0 1.122 0.248 1.502 0.679 c 0.38 0.432 0.555 1.006 0.481 1.577 l -1.674 12.979 c -0.129 0.997 -0.978 1.744 -1.983 1.744 h -9.179 V 86 z"
        transform=" matrix(1 0 0 1 0 0) "
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default FacebookIcon;
