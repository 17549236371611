import { useMutation, useQuery } from "@apollo/client";
import {
  DialogBody,
  DialogStep,
  DialogStepButtonProps,
  Intent,
  MultistepDialog,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CREATE_ENTRIES } from "../../graphql/mutations/entry";
import { CREATE_SPLITTED_ENTRIES } from "../../graphql/mutations/splitted_entries";
import { CATEGORIES_LIST } from "../../graphql/queries/categories";
import { PAYEE_LIST } from "../../graphql/queries/payees";
import {
  FinalEntryToInsert,
  parseDataForImportPreview,
  prepareDataForFinalCheck,
  PreparedEntry,
  prepareEntryForInsert,
} from "../../utils/importUtils";
import { graphQlError } from "../../utils/utils";
import CsvFileLoader, { DropColumnItem, Properties } from "../import/CsvFileLoader";
import FinalImportTable from "../import/FinalImportTable";
import PreviewTable from "../import/PreviewTable";
import { BudgetContext } from "../WithBudgetContext";
import { UserContext } from "../WithUserContext";

type ImportDialogProps = {
  accountId: number;
  isOpen: boolean;
  onClose: () => void;
  titleKey?: string;
  onImport: (data: any) => void;
};

const ImportDialog = ({
  accountId,
  isOpen,
  onClose,
  titleKey = "messages.import",
  onImport,
}: ImportDialogProps) => {
  const { t } = useTranslation();
  const userData = useContext(UserContext);
  const currentBudget = useContext(BudgetContext);
  const [createEntries] = useMutation(CREATE_ENTRIES);
  const [createSplittedEntries] = useMutation(CREATE_SPLITTED_ENTRIES);
  const { data: categories, loading: loadingCategories } = useQuery(CATEGORIES_LIST, {
    variables: { budgetId: currentBudget?.id },
  });

  const { loading: loadingPayees, data: payees } = useQuery(PAYEE_LIST);

  const [data, setData] = React.useState<string[][] | undefined>();
  const [propertyContainers, setPropertyContainers] = React.useState<Properties>({
    date: undefined,
    payee: undefined,
    income: undefined,
    expense: undefined,
    balance: undefined,
    description: undefined,
  });

  const [preparedData, setPreparedData] = React.useState<PreparedEntry[] | undefined>();
  const [finalDataToImport, setFinalDataToImport] = React.useState<
    FinalEntryToInsert[] | undefined
  >();

  const _onCancel = () => {
    setData(undefined);
    setPropertyContainers({
      date: undefined,
      payee: undefined,
      income: undefined,
      expense: undefined,
      balance: undefined,
      description: undefined,
    });
    onClose();
  };

  const onDrop = (propertyKey: keyof Properties, item: DropColumnItem) => {
    const { name, columnIndex } = item;
    setPropertyContainers((prev) => ({
      ...prev,
      [propertyKey]: {
        name,
        columnIndex,
      },
    }));
  };

  const requiredFields = ["date", "expense"].map(
    (key) => propertyContainers[key as keyof Properties]
  );

  const nextButtonProps: DialogStepButtonProps = {
    disabled: data === undefined || _.some(requiredFields, _.isNil),
    tooltipContent: data === undefined ? t("tooltips.upload_file_to_continue") : undefined,
  };

  const onChangeStep = (stepId: string) => {
    switch (stepId) {
      case "preview":
        setPreparedData(parseDataForImportPreview(_.tail(data) as string[][], propertyContainers));
        break;
      case "final_check":
        setFinalDataToImport(
          prepareDataForFinalCheck(
            preparedData as PreparedEntry[],
            categories?.categories,
            payees?.payees
          )
        );
        break;
    }
  };

  const importData = () => {
    const importingData = _.compact(
      _.map(finalDataToImport, (entry) => {
        if (entry.checked === false) {
          return null;
        }
        return prepareEntryForInsert(
          entry,
          accountId,
          userData?.user_id as string,
          currentBudget?.id as number
        );
      })
    );

    createEntries({
      variables: {
        objects: _.map(importingData, (entry) => _.omit(entry, ["done", "splitted_entries"])),
      },
    })
      .then((res) => {
        const entries = res.data.insert_entries.returning;
        return _.flatMap(entries, (entry, idx: number) => {
          return _.map(importingData[idx]?.splitted_entries, (splitted_entry) => {
            return {
              ...splitted_entry,
              category_id: splitted_entry.category_id,
              entry_id: entry.id,
              user_id: userData?.user_id,
            };
          });
        });
      })
      .then((splittedEntries) => {
        createSplittedEntries({
          variables: {
            objects: splittedEntries,
          },
        });
      })
      .then(() => {
        console.log("Imported");
      })
      .catch((err) => {
        graphQlError(err);
      })
      .finally(() => {
        setData(undefined);
        setPropertyContainers({
          date: undefined,
          payee: undefined,
          income: undefined,
          expense: undefined,
          balance: undefined,
          description: undefined,
        });
        onImport(importingData);
        onClose();
      });
  };

  const finalButtonProps: DialogStepButtonProps = {
    intent: Intent.PRIMARY,
    disabled: data === undefined,
    onClick: importData,
    text: t("actions.import"),
  };
  return (
    <MultistepDialog
      autoFocus
      canEscapeKeyClose
      canOutsideClickClose={false}
      className="import-dialog transition-all"
      style={{ width: _.isEmpty(data) ? "400px" : "calc(100vw - 200px)" }}
      isOpen={isOpen}
      isCloseButtonShown
      onClose={_onCancel}
      onChange={onChangeStep}
      title={t(titleKey)}
      icon={IconNames.INFO_SIGN}
      navigationPosition={"top"}
      showCloseButtonInFooter
      usePortal
      finalButtonProps={finalButtonProps}
      nextButtonProps={nextButtonProps}
    >
      <DialogStep
        id="select"
        panel={
          <CsvFileLoader
            data={data}
            onDrop={onDrop}
            setData={setData}
            propertyContainers={propertyContainers}
            setPropertyContainers={setPropertyContainers}
          />
        }
        title={t("import.select_file")}
      />
      <DialogStep
        id="preview"
        panel={
          <DialogBody>
            <PreviewTable preparedData={preparedData} setPreparedData={setPreparedData} />
          </DialogBody>
        }
        title={t("import.preview")}
      />
      <DialogStep
        id="final_check"
        panel={
          <DialogBody>
            <FinalImportTable
              finalDataToImport={finalDataToImport}
              setFinalDataToImport={setFinalDataToImport}
            />
          </DialogBody>
        }
        title={t("import.final_check")}
      />
    </MultistepDialog>
  );
};

export default ImportDialog;
