import _ from "lodash";
import { useQuery } from "@apollo/client";
import { Spinner } from "@blueprintjs/core";
import React, { useContext } from "react";
import { CATEGORIES_LIST } from "../../graphql/queries/categories";
import { ICategory } from "../../types/types";
import FormBuilder from "./FormBuilder";
import CategorySchema from "./schemas/category";
import { BudgetContext } from "../WithBudgetContext";
import { UserContext } from "../WithUserContext";

const CategoryForm = ({
  category,
  onCancel,
  onApply,
  onChange,
}: {
  category: ICategory;
  onApply: (account: ICategory) => Promise<any>;
  onCancel: () => void;
  onChange: (key: string, value: any) => void;
}) => {
  const currentBudget = useContext(BudgetContext);
  const userData = useContext(UserContext);
  const { loading } = useQuery(CATEGORIES_LIST, {
    variables: { budgetId: currentBudget?.id },
  });

  return loading ? (
    <Spinner />
  ) : (
    <div>
      <FormBuilder
        // formSchema={_.extend({}, CategorySchema, {
        //   ..._.extend(CategorySchema.fields.parentCategory, {
        //     options: data?.categories,
        //     selectedOption: category.parentCategory?.name,
        //   }),
        // })}
        formSchema={CategorySchema}
        entity={category}
        onChange={onChange}
        onApply={onApply}
        onCancel={onCancel}
        additionalOptions={{
          isTransfer: false,
          filterOutCategories: [],
          parentCategory: {
            filterOutNestedCategories: true,
          },
        }}
      />
    </div>
  );
};

CategoryForm.propTypes = {};

export default CategoryForm;
