import React, { FC } from "react";
import { IBudgetSection } from "../../types/types";
import FormBuilder from "./FormBuilder";
import BudgetSectionSchema from "./schemas/budgetSection";

interface INewBudgetSectionForm {
  budgetSection: IBudgetSection;
  onChange: (key: string, value: string) => void;
  onApply: (budgetSection: IBudgetSection) => Promise<any>;
  onCancel: () => void;
}

const NewBudgetSectionForm: FC<INewBudgetSectionForm> = ({
  budgetSection,
  onChange,
  onApply,
  onCancel,
}) => {
  return (
    <div>
      <FormBuilder
        formSchema={BudgetSectionSchema}
        entity={budgetSection}
        onChange={onChange}
        onApply={onApply}
        onCancel={onCancel}
        additionalOptions={{}}
      />
    </div>
  );
};

export default NewBudgetSectionForm;
