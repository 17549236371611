import _ from "lodash";
import { DateTime } from "luxon";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../components/WithUserContext";
import { useSelectedDateStore } from "../stores/useSelectedDateStore";
import useUrlQuery from "./useUrlQuery";
import { dateFormat } from "../settings";

const useSelectedDate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useContext(UserContext);
  const query = useUrlQuery();
  const { selectedDate, setSelectedDate } = useSelectedDateStore();

  const initialDate = query.get("date")
    ? DateTime.fromFormat(decodeURIComponent(query.get("date") as string), dateFormat)
    : DateTime.now();

  const currentBudget = _.first(userData?.budget_users_connectors)?.budget;
  //@ts-ignore
  const startDate = useMemo(() => DateTime.fromISO(currentBudget?.created_at), [currentBudget]);
  const currentDate = useMemo(() => (initialDate ? initialDate : DateTime.now()), [initialDate]);
  // const [selectedDate, setSelectedDate] = useState(currentDate);

  useEffect(() => {
    setSelectedDate(currentDate);
  }, []);

  const updateDate = useCallback(
    (newDate: DateTime) => {
      const params = new URLSearchParams({
        date: encodeURIComponent(newDate.toFormat(dateFormat)),
      });
      navigate({ pathname: location.pathname, search: params.toString() });
      setSelectedDate(newDate);
    },
    [history, location.pathname]
  );

  const setCurrentMonthAsSelectedDate = useCallback(() => {
    updateDate(DateTime.now());
  }, [updateDate]);

  const setPrevMonthAsSelectedDate = useCallback(() => {
    if (selectedDate > startDate) {
      return updateDate(_.clone(selectedDate.minus({ months: 1 })));
    }
  }, [selectedDate, startDate, updateDate]);

  const setNextMonthAsSelectedDate = useCallback(
    () => updateDate(_.clone(selectedDate.plus({ months: 1 }))),
    [selectedDate, updateDate]
  );

  return {
    selectedDate,
    setCurrentMonthAsSelectedDate,
    setPrevMonthAsSelectedDate,
    setNextMonthAsSelectedDate,
  };
};

export default useSelectedDate;
